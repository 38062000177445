import { createReducer, on } from '@ngrx/store';
import { SchoolYear } from '../../interfaces/school-year.interface';
import { SchoolYearsActions } from './school-years-action-types';

export interface SchoolYearsState {
  schoolYearsData: SchoolYear[];
  schoolYearsLoaded: boolean;
  schoolYearsError: string;
  selectedSchoolYear: SchoolYear;
}

const initialState: SchoolYearsState = {
  schoolYearsData: [],
  schoolYearsLoaded: false,
  schoolYearsError: '',
  selectedSchoolYear: undefined,
};

export const schoolYearsReducer = createReducer<SchoolYearsState>(
  initialState,

  // Load School Years
  on(
    SchoolYearsActions.loadSchoolYears,
    (state, action): SchoolYearsState => ({
      ...initialState,
    })
  ),

  on(
    SchoolYearsActions.loadSchoolYearsSuccess,
    (state, action): SchoolYearsState => ({
      ...state,
      schoolYearsData: action.schoolYears,
      schoolYearsLoaded: true,
      schoolYearsError: '',
    })
  ),

  on(
    SchoolYearsActions.loadSchoolYearsFailure,
    (state, action): SchoolYearsState => ({
      ...initialState,
      schoolYearsError: action.error,
    })
  ),

  // Set Current School Year As Selected School Year
  on(
    SchoolYearsActions.setCurrentSchoolYearAsSelectedSchoolYearSuccess,
    (state, action): SchoolYearsState => ({
      ...state,
      selectedSchoolYear: action.currentSchoolYear,
    })
  ),

  on(
    SchoolYearsActions.setCurrentSchoolYearAsSelectedSchoolYearFailure,
    (state, action): SchoolYearsState => ({
      ...state,
      selectedSchoolYear: undefined,
    })
  ),

  // Set Selected School Year
  on(
    SchoolYearsActions.setSelectedSchoolYear,
    (state, action): SchoolYearsState => ({
      ...state,
      selectedSchoolYear: action.selectedSchoolYear,
    })
  )
);
