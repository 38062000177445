import { Holiday } from '../../../pages/employee-work-time/interfaces/holiday.interface';
import { createReducer, on } from '@ngrx/store';
import { HolidaysActions } from './holidays-action-types';
import { act } from '@ngrx/effects';

export interface HolidaysState {
  holidaysData: Holiday[];
  holidaysLoaded: boolean;
  holidaysError: string;
}

const initialState: HolidaysState = {
  holidaysData: [],
  holidaysLoaded: false,
  holidaysError: '',
};

export const holidaysReducer = createReducer(
  initialState,

  // Load Holidays
  on(
    HolidaysActions.loadHolidaysSuccess,
    (state, action): HolidaysState => ({
      ...state,
      holidaysData: action.holidays,
      holidaysLoaded: true,
      holidaysError: '',
    })
  ),

  on(
    HolidaysActions.loadHolidaysFailure,
    (state, action): HolidaysState => ({
      ...initialState,
      holidaysData: [],
      holidaysLoaded: false,
      holidaysError: action.error,
    })
  )
);
