import { props, createAction } from '@ngrx/store';
import { SchoolYear } from '../../../../shared/interfaces/school-year.interface';
import { EmployeeContract } from '../../employee-contracts/interfaces/employee-contract.interface';
import { EmployeeWorkTime } from '../interfaces/employee-work-time.interface';
import { Holiday } from '../../interfaces/holiday.interface';

// Load Employee Work Time
export const loadEmployeeWorkTime = createAction(
  '[ WorkTimeDashboardPageContainer ] Load Employee Work Time',
  props<{ employeeId: string; schoolYear: SchoolYear }>()
);

export const loadEmployeeWorkTimeSuccess = createAction(
  '[ WorkTimeDataEffects ] Load Employee Work Time Success',
  props<{ employeeWorkTime: EmployeeWorkTime[] }>()
);

export const loadEmployeeWorkTimeFailure = createAction(
  '[ WorkTimeDataEffects ] Load Employee Work Time Failed',
  props<{ error: string }>()
);

// Calculate Employee Work Time To Work In Date Range
export const calculateEmployeeWorkTimeToWorkInDateRange = createAction(
  '[ Work Time Data Calculation Service ] Calculate Employee Work Time To Work In Date Range',
  props<{
    startDate: Date;
    endDate: Date;
    contracts: EmployeeContract[];
  }>()
);

export const calculateEmployeeWorkTimeToWorkInDateRangeSuccess = createAction(
  '[ WorkTimeDataEffects ] Calculate Employee Work Time To Work In Date Range Success',
  props<{
    workTimeDaysToWork: number;
    workTimeToWorkInMinutes: number;
  }>()
);

export const calculateEmployeeWorkTimeToWorkInDateRangeFailure = createAction(
  '[ WorkTimeDataEffects ] Calculate Employee Work Time To Work In Date Range Failed',
  props<{ error: string }>()
);

// Calculate Employee Work Time To Work Until Today
export const calculateEmployeeWorkTimeToWorkUntilToday = createAction(
  '[ Work Time Data Calculation Service ] Calculate Employee Work Time To Work Until Today',
  props<{
    startDate: Date;
    endDate: Date;
    contracts: EmployeeContract[];
    holidays: Holiday[];
  }>()
);

export const calculateEmployeeWorkTimeToWorkUntilTodaySuccess = createAction(
  '[ WorkTimeDataEffects ] Calculate Employee Work Time To Work Until Today Success',
  props<{
    workTimeDaysToWorkUntilToday: number;
    workTimeToWorkInMinutesUntilToday: number;
  }>()
);

export const calculateEmployeeWorkTimeToWorkUntilTodayFailure = createAction(
  '[ WorkTimeDataEffects ] Calculate Employee Work Time To Work Until Today Failed',
  props<{ error: string }>()
);

// Calculate Employee Work Time To Work In School Year
export const calculateEmployeeWorkTimeToWorkInSchoolYear = createAction(
  '[ Work Time Data Calculation Service ] Calculate Employee Work Time In SchoolYear Today',
  props<{
    startDate: Date;
    endDate: Date;
    contracts: EmployeeContract[];
  }>()
);

export const calculateEmployeeWorkTimeToWorkInSchoolYearSuccess = createAction(
  '[ WorkTimeDataEffects ] Calculate Employee Work Time To Work In SchoolYear Success',
  props<{
    workTimeDaysToWorkInSchoolYear: number;
    workTimeToWorkInMinutesInSchoolYear: number;
  }>()
);

export const calculateEmployeeWorkTimeToWorkInSchoolYearFailure = createAction(
  '[ WorkTimeDataEffects ] Calculate Employee Work Time To Work In SchoolYear Failed',
  props<{ error: string }>()
);

// Calculate Work Time Worked In Date Range
export const calculateEmployeeWorkTimeWorkedInDateRange = createAction(
  '[ Work Time Data Calculation Service ] Calculate Employee Work Time Worked In Date Range',
  props<{
    startDate: Date;
    endDate: Date;
    workTime: EmployeeWorkTime[];
    contracts: EmployeeContract[];
  }>()
);

export const calculateEmployeeWorkTimeWorkedInDateRangeSuccess = createAction(
  '[ WorkTimeDataEffects ] Calculate Employee Work Time Worked In Date Range Success',
  props<{
    employeeWorkTimeInContracts: EmployeeWorkTime[];
    employeeWorkTimeOutsideContracts: EmployeeWorkTime[];
    employeeWorkTimeOnWeekends: EmployeeWorkTime[];
    employeeWorkTimeWithMoreThan10Hours: EmployeeWorkTime[];
    employeeWorkTimeWithoutBreakTime: EmployeeWorkTime[];
    employeeWorkTimeWorkedInMinutes: number;
  }>()
);

export const calculateEmployeeWorkTimeWorkedInDateRangeFailure = createAction(
  '[ WorkTimeDataEffects ] Calculate Employee Work Time Worked In Date Range Failed',
  props<{ error: string }>()
);

// Calculate Work Time Worked Until Today
export const calculateEmployeeWorkTimeWorkedUntilToday = createAction(
  '[ Work Time Data Calculation Service ] Calculate Employee Work Time Worked Until Today',
  props<{
    startDate: Date;
    endDate: Date;
    workTime: EmployeeWorkTime[];
    contracts: EmployeeContract[];
  }>()
);

export const calculateEmployeeWorkTimeWorkedUntilTodaySuccess = createAction(
  '[ WorkTimeDataEffects ] Calculate Employee Work Time Worked Until Today Success',
  props<{
    employeeWorkTimeInContractsUntilToday: EmployeeWorkTime[];
    employeeWorkTimeOutsideContractsUntilToday: EmployeeWorkTime[];
    employeeWorkTimeOnWeekendsUntilToday: EmployeeWorkTime[];
    employeeWorkTimeWithMoreThan10HoursUntilToday: EmployeeWorkTime[];
    employeeWorkTimeWithoutBreakTimeUntilToday: EmployeeWorkTime[];
    employeeWorkTimeWorkedInMinutesUntilToday: number;
  }>()
);

export const calculateEmployeeWorkTimeWorkedUntilTodayFailure = createAction(
  '[ WorkTimeDataEffects ] Calculate Employee Work Time Worked Until Today Failed',
  props<{ error: string }>()
);

// Calculate Work Time Worked In School Year
export const calculateEmployeeWorkTimeWorkedInSchoolYear = createAction(
  '[ Work Time Data Calculation Service ] Calculate Employee Work Time In School Year Today',
  props<{
    startDate: Date;
    endDate: Date;
    workTime: EmployeeWorkTime[];
    contracts: EmployeeContract[];
  }>()
);

export const calculateEmployeeWorkTimeWorkedInSchoolYearSuccess = createAction(
  '[ WorkTimeDataEffects ] Calculate Employee Work Time Worked In School Year Success',
  props<{
    employeeWorkTimeInContractsInSchoolYear: EmployeeWorkTime[];
    employeeWorkTimeOutsideContractsInSchoolYear: EmployeeWorkTime[];
    employeeWorkTimeOnWeekendsInSchoolYear: EmployeeWorkTime[];
    employeeWorkTimeWithMoreThan10HoursInSchoolYear: EmployeeWorkTime[];
    employeeWorkTimeWithoutBreakTimeInSchoolYear: EmployeeWorkTime[];
    employeeWorkTimeWorkedInMinutesInSchoolYear: number;
  }>()
);

export const calculateEmployeeWorkTimeWorkedInSchoolYearFailure = createAction(
  '[ WorkTimeDataEffects ] Calculate Employee Work Time Worked In School Year Failed',
  props<{ error: string }>()
);

// Select Employee Work Time
export const selectEmployeeWorkTime = createAction(
  '[ Work Time List Container ] Select Employee Work Time',
  props<{ employeeWorkTime: EmployeeWorkTime }>()
);

// Add Work Time Data
export const addEmployeeWorkTime = createAction(
  '[ AddUpdateEmployeeWorkTimeContainer ] Add Employee Work Time',
  props<{ employeeWorkTime: EmployeeWorkTime }>()
);

export const addEmployeeWorkTimeSuccess = createAction(
  '[ EmployeeWorkTimeEffects ] Add Employee Work Time Success',
  props<{ employeeWorkTime: EmployeeWorkTime }>()
);

export const addWorkTimeDataFailure = createAction(
  '[ EmployeeWorkTimeEffects ] Add Employee Work Time Failed',
  props<{ error: string }>()
);

// Update Employee Work Time
export const updateEmployeeWorkTime = createAction(
  '[ AddUpdateEmployeeWorkTimeContainer ] Update Employee Work Time',
  props<{ employeeWorkTime: EmployeeWorkTime }>()
);

export const updateEmployeeWorkTimeSuccess = createAction(
  '[ EmployeeWorkTimeEffects ] Update Employee Work Time Success',
  props<{ employeeWorkTime: EmployeeWorkTime }>()
);

export const updateEmployeeWorkTimeFailure = createAction(
  '[ EmployeeWorkTimeEffects ] Update Employee Work Time Failed',
  props<{ error: string }>()
);

// Delete Work Time Data
export const deleteEmployeeWorkTime = createAction(
  '[ Work Time List Container ] Delete Employee Work Time',
  props<{ employeeWorkTime: EmployeeWorkTime }>()
);

export const deleteEmployeeWorkTimeSuccess = createAction(
  '[ Work Time Data Effects ] Delete Emplyoee Work Time Success',
  props<{ employeeWorkTime: EmployeeWorkTime }>()
);

export const deleteEmployeeWorkTimeFailure = createAction(
  '[ Work Time Data Effects ] Delete Employee Work Time Failed',
  props<{ error: string }>()
);
