import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ParentInformationsMapState } from '../parent-informations-map.reducer';

export const selectParentInformationCategoriesFeatureSelector =
  createFeatureSelector<ParentInformationsMapState>('parent-informations');

export const selectParentInformationCategories = createSelector(
  selectParentInformationCategoriesFeatureSelector,
  (state) => state.parentInformationCategories.parentInformationCategoriesData
);

export const selectParentInformationCategoriesLoaded = createSelector(
  selectParentInformationCategoriesFeatureSelector,
  (state) =>
    state.parentInformationCategories.parentInformationCategoriesDataLoaded
);

export const selectParentInformationCategoriesError = createSelector(
  selectParentInformationCategoriesFeatureSelector,
  (state) =>
    state.parentInformationCategories.parentInformationCategoriesDataError
);

export const selectSelectedParentInformationCategory = createSelector(
  selectParentInformationCategoriesFeatureSelector,
  (state) =>
    state.parentInformationCategories.selectedParentInformationCategoryData
);
