import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginContainerComponent } from './pages/auth/components/login/login-container/login-container.component';
import { AuthGuard } from './pages/auth/services/auth.guard';
import { EmployeeAbsenceCategoriesResolver } from './pages/employee-work-time/employee-absence-categories/services/employee-absence-categories.resolver';
import { EmployeeAbsencesResolver } from './pages/employee-work-time/employee-absences/services/employee-absences.resolver';
import { EmployeeCompanyVacationsResolver } from './pages/employee-work-time/employee-company-vacations/services/employee-company-vacations.resolver';
import { EmployeeContractsResolver } from './pages/employee-work-time/employee-contracts/services/employee-contracts.resolver';
import { EmployeePaidOvertimeResolver } from './pages/employee-work-time/employee-paid-overtime/services/employee-paid-overtime.resolver';
import { EmployeeVacationsResolver } from './pages/employee-work-time/employee-vacations/services/employee-vacations.resolver';
import { EmployeeWorkTimeResolver } from './pages/employee-work-time/employee-work-time/services/employee-work-time.resolver';
import { TransferredBalancesResolver } from './pages/employee-work-time/transferred-balances/services/transferred-balances.resolver';
import { HolidaysResolver } from './shared/holidays/services/holidays.resolver';
import { SchoolYearsResolver } from './shared/services/school-years/school-years.resolver';
import { CompanyVacationsResolver } from './pages/employee-work-time/company-vacations/services/company-vacations.resolver';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'login',
  //   pathMatch: 'full',
  // },
  {
    path: '',
    component: LoginContainerComponent,
  },
  {
    path: 'login',
    component: LoginContainerComponent,
  },
  {
    path: 'parents',
    loadChildren: () =>
      import(
        './pages/parents/parent-informations/parent-informations.module'
      ).then((m) => m.ParentInformationsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'employee-work-time',
    loadChildren: () =>
      import('./pages/employee-work-time/employee-work-time.module').then(
        (m) => m.EmployeeWorkTimeModule
      ),
    resolve: {
      schoolYears: SchoolYearsResolver,
      holidays: HolidaysResolver,
      employeeContracts: EmployeeContractsResolver,
      employeeWorkTime: EmployeeWorkTimeResolver,
      employeeAbsences: EmployeeAbsencesResolver,
      employeeAbsenceCategories: EmployeeAbsenceCategoriesResolver,
      companyVacations: CompanyVacationsResolver,
      employeeCompanyVacations: EmployeeCompanyVacationsResolver,
      employeeVacations: EmployeeVacationsResolver,
      transferredBalances: TransferredBalancesResolver,
      employeePaidOvertime: EmployeePaidOvertimeResolver,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
