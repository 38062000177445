import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Language } from 'src/app/pages/parents/parent-informations/interfaces/language.interface';
import { Store } from '@ngrx/store';
import { UsersDefaultLanguageActions } from 'src/app/pages/auth/state/users-default-language/users-default-language-action-types';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-default-language-selection',
  templateUrl: './default-language-selection.component.html',
  styleUrls: ['./default-language-selection.component.scss'],
})
export class DefaultLanguageSelectionComponent {
  @Input() languages: Language[];
  @Output() selectLanguage = new EventEmitter<Language>();

  constructor(private store: Store) {}

  onSelectLanguage(language: Language) {
    this.selectLanguage.emit(language);
  }
}
