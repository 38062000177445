export const convertMinutesInHoursAndMinutes = (
  minutesToConvert: number
): string => {
  let min = minutesToConvert;

  if (min < 0) {
    min = -min;
  }

  const hours = Math.floor(min / 60);
  let minutes = Math.floor(min % 60);

  let hoursAndMinutes = '';
  if (hours < 1) {
    hoursAndMinutes = `${minutes} min`;
  } else {
    hoursAndMinutes = `${hours} h ${minutes} min`;
  }

  return hoursAndMinutes;
};

export const convertMinutesInHoursAndMinutesWithPrefix = (
  minutesToConvert: number
): string => {
  let min = minutesToConvert;

  if (min < 0) {
    min = -min;
  }

  const hours = Math.floor(min / 60);
  let minutes = Math.floor(min % 60);

  let hoursAndMinutes = '';
  if (hours < 1) {
    hoursAndMinutes = `${minutes} min`;
  } else {
    hoursAndMinutes = `${hours} h ${minutes} min`;
  }

  if (minutesToConvert < 0) {
    hoursAndMinutes = `- ${hoursAndMinutes}`;
  } else {
    hoursAndMinutes = `+ ${hoursAndMinutes}`;
  }

  return hoursAndMinutes;
};

export const minuteValues = '0,5,10,15,20,25,30,35,40,45,50,55';

// export const convertTimestampsToDates = (data: any) => {
//   console.log(Object.values(data));
//   console.log(
//     Object.values(data).forEach((value) => {
//       if (value === 'st') {
//         console.log(true);
//       }
//     })
//   );
//   return data;
// };
