import { createSelector, createFeatureSelector } from '@ngrx/store';
import { SharedState } from '../shared.reducer-map';

export const selectSchoolYearsFeatureSelector =
  createFeatureSelector<SharedState>('shared');

export const selectSchoolYears = createSelector(
  selectSchoolYearsFeatureSelector,
  (state) => state.schoolYears.schoolYearsData
);

export const selectSchoolYearsLoaded = createSelector(
  selectSchoolYearsFeatureSelector,
  (state) => state.schoolYears.schoolYearsLoaded
);

export const selectSchoolYearsError = createSelector(
  selectSchoolYearsFeatureSelector,
  (state) => state.schoolYears.schoolYearsError
);

export const selectSelectedSchoolYear = createSelector(
  selectSchoolYearsFeatureSelector,
  (state) => state.schoolYears.selectedSchoolYear
);

export const selectSelectedSchoolYearLoaded = createSelector(
  selectSchoolYearsFeatureSelector,
  (state) => !!state.schoolYears.selectedSchoolYear
);

export const selectCurrentSchoolYear = createSelector(
  selectSchoolYearsFeatureSelector,
  (state) => state.schoolYears.selectedSchoolYear
);

export const selectCurrentSchoolYearLoaded = createSelector(
  selectSchoolYearsFeatureSelector,
  (state) => !!state.schoolYears.selectedSchoolYear
);
