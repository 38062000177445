import { createReducer, on, ActionReducerMap } from '@ngrx/store';
import {
  SchoolYearsState,
  schoolYearsReducer,
} from './school-years/school-years.reducer';
import { LoadingState, loadingReducer } from './loading/loading.reducer';
import { SchoolState, schoolReducer } from './school/school.reducer';
import {
  HolidaysState,
  holidaysReducer,
} from '../holidays/state/holidays.reducer';
import {
  languagesReducer,
  LanguagesState,
} from './languages/languages.reducer';

export interface SharedState {
  loading: LoadingState;
  schoolYears: SchoolYearsState;
  school: SchoolState;
  languages: LanguagesState;
  holidays: HolidaysState;
}

export const sharedReducerMap: ActionReducerMap<SharedState> = {
  loading: loadingReducer,
  schoolYears: schoolYearsReducer,
  school: schoolReducer,
  languages: languagesReducer,
  holidays: holidaysReducer,
};
