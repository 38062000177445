import { Language } from '../../../pages/parents/parent-informations/interfaces/language.interface';
import { createReducer, on } from '@ngrx/store';
import { LanguagesActions } from './languages-action-types';

export interface LanguagesState {
  languagesData: Language[];
  languagesDataLoaded: boolean;
  languagesDataError: string;
}

const initialState: LanguagesState = {
  languagesData: [],
  languagesDataLoaded: false,
  languagesDataError: '',
};

export const languagesReducer = createReducer<LanguagesState>(
  initialState,

  on(
    LanguagesActions.loadLanguagesSuccess,
    (state, action): LanguagesState => ({
      ...state,
      languagesData: action.languages,
      languagesDataLoaded: true,
      languagesDataError: '',
    })
  ),
  on(
    LanguagesActions.loadLanguagesFailure,
    (state, action): LanguagesState => ({
      ...state,
      languagesData: [],
      languagesDataLoaded: false,
      languagesDataError: action.error,
    })
  )
);
