import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-blank-slate',
  templateUrl: './blank-slate.component.html',
  styleUrls: ['./blank-slate.component.scss'],
})
export class BlankSlateComponent {

  @Input() image: string;
  @Input() title: string;
  @Input() text: string;


}
