import { Component, OnInit, OnDestroy } from '@angular/core';
import { Option } from '../../interfaces/option.interface';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
  selectSchoolYears,
  selectSelectedSchoolYear,
} from '../../state/school-years/school-years.selectors';
import { map, filter, tap } from 'rxjs/operators';
import { SchoolYearsActions } from 'src/app/shared/state/school-years/school-years-action-types';

@Component({
  selector: 'app-select-school-year',
  templateUrl: './select-school-year.component.html',
  styleUrls: ['./select-school-year.component.scss'],
})
export class SelectSchoolYearComponent implements OnInit, OnDestroy {
  lastSchoolYears$: Observable<Option[]>;
  selectedSchoolYear$: Observable<Option>;

  onSelectedSchoolYearSubs = new Subscription();

  constructor(private store: Store) {}

  ngOnInit(): void {
    // Convert Last School Years in Option for Select School Year Component
    this.lastSchoolYears$ = this.store
      .select(selectSchoolYears)
      .pipe(
        map((lastSchoolYears) =>
          lastSchoolYears.map(
            (schoolYear) =>
              ({ id: schoolYear.id, name: schoolYear.name } as Option)
          )
        )
      );

    // Convert Selected School Year in Option for Select School Year Component
    this.selectedSchoolYear$ = this.store.select(selectSelectedSchoolYear).pipe(
      filter((selectedSchoolYear) => !!selectedSchoolYear),
      map(
        (selectedSchoolYear) =>
          ({
            id: selectedSchoolYear.id,
            name: selectedSchoolYear.name,
          } as Option)
      )
    );
  }

  onSelectedSchoolYear(selectedSchoolYearId: string) {
    this.onSelectedSchoolYearSubs = this.store
      .select(selectSchoolYears)
      .pipe(
        map((schoolYears) =>
          schoolYears.find(
            (schoolYear) => schoolYear.id === selectedSchoolYearId
          )
        ),
        tap((selectedSchoolYear) =>
          this.store.dispatch(
            SchoolYearsActions.setSelectedSchoolYear({ selectedSchoolYear })
          )
        )
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.onSelectedSchoolYearSubs.unsubscribe();
  }
}
