import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { GetUrlService } from '../../../../../shared/services/url-service/get-url.service';
import { School } from '../../../../../shared/interfaces/school.interface';
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from '@capacitor/camera';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Input() email: string;
  @Input() school: School;
  @Input() authError: any;
  @Output() login = new EventEmitter();

  form: FormGroup;

  image = null;

  tenant: boolean;

  constructor(
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private getUrlService: GetUrlService
  ) {
    const tenant = getUrlService.getHostname();
    if (tenant === 'app.rietberg-montessori.ch') {
      this.tenant = true;
    } else {
      this.tenant = false;
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: [this.email, [Validators.email, Validators.required]],
      password: ['', [Validators.minLength(6), Validators.required]],
    });
  }

  async takePicture() {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      // resultType: CameraResultType.Uri
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
    });

    console.log(image);
    if (image) {
      this.savePhoto(image);
    }

    this.image = this.sanitizer.bypassSecurityTrustResourceUrl(
      'data:image/jpeg;base64,' + image.base64String
    );
  }

  async savePhoto(photo: Photo) {
    const fileName = new Date().getTime() + '.jpeg';
  }

  onLogin() {
    this.login.emit(this.form.value);
    // this.form.reset();
  }

  reload() {
    window.location.reload();
  }
}
