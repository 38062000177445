import { createAction, props } from '@ngrx/store';
import { User } from '../../interfaces/user.interface';
import { Language } from '../../../parents/parent-informations/interfaces/language.interface';

//
export const setUsersDefaultLanguageInLocalStorage = createAction(
  '[ LanguageSelectionComponent ] Set Users Default Language in Local Storage',
  props<{ language: Language }>()
);

export const setUsersDefaultLanguageInLocalStorageSuccess = createAction(
  '[ LanguageSelectionComponent ] Set Users Default Language in Local Storage Success',
  props<{ language: Language }>()
);

export const setUsersDefaultLanguageInLocalStorageFailure = createAction(
  '[ LanguageSelectionComponent ] Set Users Default Language in Local Storage Failed',
  props<{ error: string }>()
);

// Load Users Lang
export const loadUsersLanguageFromLocalStorage = createAction(
  '[ UserDefaultLanguageEffects ] Load Language From Local Storage',
  props<{ languages: Language[] }>()
);

export const loadLanguageFromLocalStorageSuccess = createAction(
  '[ UserDefaultLanguageEffects ] Load Language From Local Storage Success',
  props<{ language: Language }>()
);

export const loadLanguageFromLocalStorageFailure = createAction(
  '[ UserDefaultLanguageEffects ] Load Language From Local Storage Failed',
  props<{ error: string }>()
);
