import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmployeeWorkTimeReducerMapState } from '../../state/work-time.reducer-map';

export const selectCompanyVacationsFeatureSelector =
  createFeatureSelector<EmployeeWorkTimeReducerMapState>(
    'employee-work-time-reducer-map'
  );

export const selectFetchedCompanyVacations = createSelector(
  selectCompanyVacationsFeatureSelector,
  (state) => state.companyVacations.fetchedCompanyVacations
);

export const selectFetchedCompanyVacationsLoaded = createSelector(
  selectCompanyVacationsFeatureSelector,
  (state) => state.companyVacations.fetchedCompanyVacationsLoaded
);

export const selectFetchedCompanyVacationsError = createSelector(
  selectCompanyVacationsFeatureSelector,
  (state) => state.companyVacations.fetchedCompanyVacationsError
);

export const selectCompanyVacations = createSelector(
  selectCompanyVacationsFeatureSelector,
  (state) => state.companyVacations.fetchedCompanyVacations
);

export const selectCompanyVacationsLoaded = createSelector(
  selectCompanyVacationsFeatureSelector,
  (state) => state.companyVacations.fetchedCompanyVacationsLoaded
);

export const selectCompanyVacationsError = createSelector(
  selectCompanyVacationsFeatureSelector,
  (state) => state.companyVacations.fetchedCompanyVacationsError
);
