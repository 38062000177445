import { createReducer, on } from '@ngrx/store';
import { User } from '../../interfaces/user.interface';
import { AuthActions } from './auth-action-types';

export interface AuthState {
  user: User;
  userLoaded: boolean;
  userError: string;
  isAuthenticated: boolean;
}

const initalState: AuthState = {
  user: null,
  userLoaded: false,
  userError: '',
  isAuthenticated: false,
};

export const authReducer = createReducer<AuthState>(
  initalState,

  on(
    AuthActions.loginSuccess,
    (state, action): AuthState => ({
      ...state,
      user: action.user,
      userLoaded: true,
      userError: '',
      isAuthenticated: true,
    })
  ),

  on(
    AuthActions.loginFailure,
    (state, action): AuthState => ({
      ...initalState,
      user: null,
      userLoaded: false,
      userError: action.error,
      isAuthenticated: false,
    })
  ),

  on(
    AuthActions.logout,
    (state, action): AuthState => ({
      ...initalState,
    })
  )
);
