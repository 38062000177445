import { createAction, props } from '@ngrx/store';

import { SchoolYear } from '../../../../shared/interfaces/school-year.interface';
import { EmployeeContract } from '../../employee-contracts/interfaces/employee-contract.interface';
import { EmployeeVacation } from '../interfaces/employee-vacation.interface';
import { Holiday } from '../../interfaces/holiday.interface';

// Load Employee Vacations
export const loadEmployeeVacations = createAction(
  ' [EmployeeVacationsResolver] Load Employee Vacations',
  props<{ employeeId: string; schoolYear: SchoolYear }>()
);

export const loadEmployeeVacationsSuccess = createAction(
  ' [EmployeeVacationsEffects] Load Employee Vacations Success',
  props<{ employeeVacations: EmployeeVacation[] }>()
);

export const loadEmployeeVacationsFailure = createAction(
  ' [EmployeeVacationsEffects] Load Employee Vacations Failed',
  props<{ error: string }>()
);

// Add Employee Vacations Data
export const addEmployeeVacationsData = createAction(
  '[ EmployeeVacationsResolver ] Add Employee Vacations Data',
  props<{
    employeeVacations: EmployeeVacation[];
    schoolYears: SchoolYear[];
    holidays: Holiday[];
    selectedSchoolYear: SchoolYear;
  }>()
);

export const addEmployeeVacationsDataSuccess = createAction(
  '[EmployeeVacationsResolver] Add Employee Vacations Data Success',
  props<{ employeeVacations: EmployeeVacation[] }>()
);

export const addEmployeeVacationsDataFailure = createAction(
  '[EmployeeVacationsResolver] Add Employee Vacations Data Failure',
  props<{ error: string }>()
);

// Select Employee Vacation
export const selectEmployeeVacation = createAction(
  '[ EmployeeVacationsListContainer ] Select Employee Vacation',
  props<{ selectedEmployeeVacation: EmployeeVacation }>()
);

// Calculate Employee Vacations in Date Range
export const calculateEmployeeVacationsInDateRange = createAction(
  '[ EmployeeVacationsEffects ] Calculate Employee Vacations in Date Range',
  props<{
    startDate: Date;
    endDate: Date;
    employeeVacations: EmployeeVacation[];
    holidays: Holiday[];
    employeeContracts: EmployeeContract[];
  }>()
);

export const calculateEmployeeVacationsInDateRangeSuccess = createAction(
  '[ EmployeeVacationsEffects ] Calculate Employee Vacations in Date Range Success',
  props<{
    employeeVacationsInMinutes: number;
    employeeVacationsDays: number;
  }>()
);

export const calculateEmployeeVacationsInDateRangeFailure = createAction(
  '[ EmployeeVacationsEffects ] Calculate Employee Vacations in Date Range Failure',
  props<{ error: string }>()
);

// Calculate Employee Vacations Until Today
export const calculateEmployeeVacationsUntilToday = createAction(
  '[ EmployeeVacationsEffects ] Calculate Employee Vacations Until Today',
  props<{
    startDate: Date;
    endDate: Date;
    employeeVacations: EmployeeVacation[];
    holidays: Holiday[];
    employeeContracts: EmployeeContract[];
  }>()
);

export const calculateEmployeeVacationsUntilTodaySuccess = createAction(
  '[ EmployeeVacationsEffects ] Calculate Employee Vacations Until Today Success',
  props<{
    employeeVacationsInMinutesUntilToday: number;
    employeeVacationsDaysUntilToday: number;
  }>()
);

export const calculateEmployeeVacationsUntilTodayFailure = createAction(
  '[ EmployeeVacationsEffects ] Calculate Employee Vacations Until Today Failure',
  props<{ error: string }>()
);

// Calculate Employee Vacations In School Year
export const calculateEmployeeVacationsInSchoolYear = createAction(
  '[ EmployeeVacationsEffects ] Calculate Employee Vacations In School Year',
  props<{
    startDate: Date;
    endDate: Date;
    employeeVacations: EmployeeVacation[];
    holidays: Holiday[];
    employeeContracts: EmployeeContract[];
  }>()
);

export const calculateEmployeeVacationsInSchoolYearSuccess = createAction(
  '[ EmployeeVacationsEffects ] Calculate Employee Vacations  In School Year Success',
  props<{
    employeeVacationsInMinutesInSchoolYear: number;
    employeeVacationsDaysInSchoolYear: number;
  }>()
);

export const calculateEmployeeVacationsInSchoolYearFailure = createAction(
  '[ EmployeeVacationsEffects ] Calculate Employee Vacations  In School Year Failure',
  props<{ error: string }>()
);
