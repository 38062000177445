import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { switchMap, tap, map, filter, first } from 'rxjs/operators';
import { EmployeeContractsActions } from '../state/employee-contracts-action-types';
import { selectEmployeeContractsLoaded } from '../state/employee-contracts.selectors';
import { selectSelectedEmployee } from '../../employee/state/employee.selectors';
import { selectUser } from 'src/app/pages/auth/state/auth/auth.selectors';

@Injectable({ providedIn: 'root' })
export class EmployeeContractsResolver {
  constructor(private store: Store) {}
  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(selectEmployeeContractsLoaded).pipe(
      switchMap((loaded) => {
        if (!loaded) {
          return this.store.select(selectUser).pipe(
            map((selectedEmployee) =>
              this.store.dispatch(
                EmployeeContractsActions.loadEmployeeContracts({
                  employeeId: selectedEmployee.id,
                })
              )
            ),
            map(() => false)
          );
        } else {
          return of(true);
        }
      }),
      filter((loaded) => !!loaded),
      first()
    );
  }
}
