import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { School } from '../../interfaces/school.interface';
import { selectSchool } from '../../state/school/school.selectors';
import {
  selectUser,
  selectIsParent,
} from '../../../pages/auth/state/auth/auth.selectors';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title: string;
  @Input() backButton: boolean;
  @Input() backLink: string[];

  school$: Observable<School>;
  isParent$: Observable<boolean>;
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.isParent$ = this.store.select(selectIsParent);

    this.school$ = this.store.select(selectSchool);
  }
}
