import { Component, OnInit } from '@angular/core';
import { AuthService } from './pages/auth/services/auth.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  selectUser,
  selectIsParent,
} from './pages/auth/state/auth/auth.selectors';
import { User } from './pages/auth/interfaces/user.interface';
import { selectLoading } from './shared/state/loading/loading.selectors';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { GetUrlService } from './shared/services/url-service/get-url.service';
import { SchoolActions } from './shared/state/school/school-action-types';
import { SchoolService } from './shared/services/school/school.service';
import { ParentInformationCategory } from './pages/parents/parent-informations/interfaces/parent-information-category.interface';
import { selectParentInformationCategories } from './pages/parents/parent-informations/state/parent-information-categories/parent-information-categories.selectors';
import { SwUpdate } from '@angular/service-worker';
import { AlertController } from '@ionic/angular';
import { LoadingActions } from './shared/state/loading/loading-action-types';
import { Auth } from '@angular/fire/auth/firebase';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    // { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Arbeitszeit', url: '/work-time', icon: 'paper-plane' },
    {
      title: 'Meine Ferien',
      url: '/work-time/tabs/taken-vacations',
      icon: 'airplane',
    },
    {
      title: 'Verträge',
      url: '/work-time/tabs/contracts',
      icon: 'document-text',
    },
    {
      title: 'Absenzen',
      url: '/work-time/tabs/absences',
      icon: 'bandage',
    },
    // { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  public parentPages = [
    // { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Elterninformationen', url: '/parents/list', icon: 'paper-plane' },
    // {
    //   title: 'Meine Ferien',
    //   url: '/work-time/tabs/taken-vacations',
    //   icon: 'airplane',
    // },
    // {
    //   title: 'Verträge',
    //   url: '/work-time/tabs/contracts',
    //   icon: 'document-text',
    // },
    // {
    //   title: 'Absenzen',
    //   url: '/work-time/tabs/absences',
    //   icon: 'bandage',
    // },
    // { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  loading$: Observable<boolean>;

  user$: Observable<User>;
  isParent$: Observable<boolean>;
  parentInformationCategories$: Observable<ParentInformationCategory[]>;

  constructor(
    private authService: AuthService,
    private store: Store,
    getUrlService: GetUrlService,
    private schoolService: SchoolService,
    private router: Router,
    private swUpdate: SwUpdate,
    private alertController: AlertController
  ) {
    const tenant = getUrlService.getHostname();
    const fakeTenant = 'app.rietberg-montessori.ch';
    const schoolUrl = fakeTenant.substring(4);
    console.log(schoolUrl);

    this.store.dispatch(SchoolActions.findSchool({ schoolUrl }));

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.store.dispatch(LoadingActions.loadingTrue());
      }

      if (event instanceof NavigationEnd) {
        this.store.dispatch(LoadingActions.loadingFalse());
      }
    });
  }

  ngOnInit(): void {
    this.loading$ = this.store.select(selectLoading);
    this.user$ = this.store.select(selectUser);
    this.isParent$ = this.store.select(selectIsParent);
    this.parentInformationCategories$ = this.store.select(
      selectParentInformationCategories
    );
    this.checkForUpdates();
  }

  checkForUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        if (event.type === 'VERSION_READY') {
          this.presentAlert();
        }
      });
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Neue Version verfügbar! Jetzt installieren!',
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
          // handler: () => {
          //   this.handlerMessage = 'Alert canceled';
          // },
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            window.location.reload();
          },
        },
      ],
    });

    await alert.present();
  }
}
