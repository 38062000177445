import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Option } from '../../interfaces/option.interface';

@Component({
  selector: 'app-ion-select',
  templateUrl: './ion-select.component.html',
  styleUrls: ['./ion-select.component.scss'],
})
export class IonSelectComponent {
  @Input() options: Option[];
  @Input() initalValue: Option;
  @Input() title: string;
  @Input() label: string;
  @Output() changeSelection = new EventEmitter<string>();

  onChangeSelection(selectedOption: any) {
    this.changeSelection.emit(selectedOption.detail.value);
  }
}
