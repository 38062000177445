import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectHolidaysLoaded } from '../state/holidays.selectors';
import {
  switchMap,
  tap,
  withLatestFrom,
  map,
  filter,
  first,
} from 'rxjs/operators';
import { selectSchoolId } from '../../../pages/auth/state/auth/auth.selectors';
import { HolidaysActions } from 'src/app/shared/holidays/state/holidays-action-types';
import { selectSelectedSchoolYear } from '../../state/school-years/school-years.selectors';

@Injectable({ providedIn: 'root' })
export class HolidaysResolver implements Resolve<boolean> {
  constructor(private store: Store) {}
  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(selectHolidaysLoaded).pipe(
      switchMap((loaded) => {
        if (!loaded) {
          return this.store.select(selectSelectedSchoolYear).pipe(
            withLatestFrom(this.store.select(selectSchoolId)),
            filter(([schoolYear, schoolId]) => !!schoolYear && !!schoolId),
            tap(([schoolYear, schoolId]) =>
              this.store.dispatch(
                HolidaysActions.loadHolidays({ schoolYear, schoolId })
              )
            ),
            map(() => false)
          );
        } else {
          return of(true);
        }
      }),
      filter((loaded) => !!loaded),
      first()
    );
  }
}
