import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  CollectionReference,
  collectionData,
} from '@angular/fire/firestore';
import { Language } from 'src/app/pages/parents/parent-informations/interfaces/language.interface';
import { map, catchError } from 'rxjs/operators';
import { throwError, EMPTY } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguagesService {
  constructor(private firestore: Firestore) {}

  loadLanguages(schoolId: string) {
    const languagesRef = collection(
      this.firestore,
      `schools/${schoolId}/languages`
    ) as CollectionReference<Language>;

    return collectionData(languagesRef, { idField: 'id' }).pipe(
      catchError((error) => {
        throwError(error);
        return EMPTY;
      })
    );
  }
}
