import { createSelector, createFeatureSelector } from '@ngrx/store';
import { EmployeeWorkTimeReducerMapState } from '../../state/work-time.reducer-map';

export const selectEmployeeVacationsFeatureSelector =
  createFeatureSelector<EmployeeWorkTimeReducerMapState>(
    'employee-work-time-reducer-map'
  );

// Fetched Employee Vacations
export const selectFetchedEmployeeVacations = createSelector(
  selectEmployeeVacationsFeatureSelector,
  (state) => state.employeeVacations.fetchedEmployeeVacations
);

export const selectFetchedEmployeeVacationsLoaded = createSelector(
  selectEmployeeVacationsFeatureSelector,
  (state) => state.employeeVacations.fetchedEmployeeVacationsLoaded
);

export const selectFetchedEmployeeVacationsError = createSelector(
  selectEmployeeVacationsFeatureSelector,
  (state) => state.employeeVacations.fetchedEmployeeVacationsLoaded
);

// Employee Vacations
export const selectEmployeeVacations = createSelector(
  selectEmployeeVacationsFeatureSelector,
  (state) => state.employeeVacations.employeeVacationsData
);

export const selectEmployeeVacationsLoaded = createSelector(
  selectEmployeeVacationsFeatureSelector,
  (state) => state.employeeVacations.employeeVacationsDataLoaded
);

export const selectEmployeeVacationsError = createSelector(
  selectEmployeeVacationsFeatureSelector,
  (state) => state.employeeVacations.employeeVacationsDataError
);

// Selected Employee Vacation
export const selectSelectedEmployeeVacation = createSelector(
  selectEmployeeVacationsFeatureSelector,
  (state) => state.employeeVacations.selectedEmployeeVacation
);

// Employee Vacations In Date Range
export const selectEmployeeVacationsInMinutes = createSelector(
  selectEmployeeVacationsFeatureSelector,
  (state) => state.employeeVacations.employeeVacationsInMinutes
);

export const selectEmployeeVacationsInDays = createSelector(
  selectEmployeeVacationsFeatureSelector,
  (state) => state.employeeVacations.employeeVacationsDays
);

export const selectEmployeeVacationsInDateRangeError = createSelector(
  selectEmployeeVacationsFeatureSelector,
  (state) => state.employeeVacations.employeeVacationsError
);

// Employee Vacations Until Today
export const selectEmployeeVacationsInMinutesUntilToday = createSelector(
  selectEmployeeVacationsFeatureSelector,
  (state) => state.employeeVacations.employeeVacationsInMinutesUntilToday
);

export const selectEmployeeVacationsInDaysUntilToday = createSelector(
  selectEmployeeVacationsFeatureSelector,
  (state) => state.employeeVacations.employeeVacationsDaysUntilToday
);

export const selectEmployeeVacationsUntilTodayError = createSelector(
  selectEmployeeVacationsFeatureSelector,
  (state) => state.employeeVacations.employeeVacationsError
);

// Employee Vacations In School Year
export const selectEmployeeVacationsInMinutesInSchoolYear = createSelector(
  selectEmployeeVacationsFeatureSelector,
  (state) => state.employeeVacations.employeeVacationsInMinutesInSchoolYear
);

export const selectEmployeeVacationsInDaysInSchoolYear = createSelector(
  selectEmployeeVacationsFeatureSelector,
  (state) => state.employeeVacations.employeeVacationsDaysInSchoolYear
);

export const selectEmployeeVacationsInSchoolYearError = createSelector(
  selectEmployeeVacationsFeatureSelector,
  (state) => state.employeeVacations.employeeVacationsError
);
