import { createAction, props } from '@ngrx/store';
import { Holiday } from '../../../pages/employee-work-time/interfaces/holiday.interface';
import { EmployeeContract } from '../../../pages/employee-work-time/employee-contracts/interfaces/employee-contract.interface';
import { SchoolYear } from '../../interfaces/school-year.interface';

// Load Holidays
export const loadHolidays = createAction(
  '[ HolidaysResolver ] Load Holidays',
  props<{ schoolYear: SchoolYear; schoolId: string }>()
);

export const loadHolidaysSuccess = createAction(
  '[ HolidaysEffects ] Load Holidays Success',
  props<{ holidays: Holiday[] }>()
);

export const loadHolidaysFailure = createAction(
  '[ HolidaysEffect ] Load Holidays Failed',
  props<{ error: string }>()
);
