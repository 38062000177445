import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmployeeWorkTimeReducerMapState } from '../work-time.reducer-map';

export const selectWorkTimeUtilsFeatureSelector =
  createFeatureSelector<EmployeeWorkTimeReducerMapState>(
    'employee-work-time-reducer-map'
  );

export const selectDateRangeDates = createSelector(
  selectWorkTimeUtilsFeatureSelector,
  (state) => state.workTimeUtils.dateRangeDates
);
