import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  Observable,
  filter,
  first,
  map,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { selectUserId } from 'src/app/pages/auth/state/auth/auth.selectors';
import { selectSelectedSchoolYear } from '../../../../shared/state/school-years/school-years.selectors';
import { selectSchoolId } from '../../../auth/state/auth/auth.selectors';
import { EmployeeCompanyVacationsActions } from '../state/employee-company-vacations-action-types';
import { selectEmployeeCompanyVacationsLoaded } from '../state/employee-company-vacations.selectors';

@Injectable({ providedIn: 'root' })
export class EmployeeCompanyVacationsResolver {
  constructor(private store: Store) {}
  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(selectEmployeeCompanyVacationsLoaded).pipe(
      switchMap((loaded) => {
        if (!loaded) {
          return this.store.select(selectUserId).pipe(
            withLatestFrom(
              this.store.select(selectSchoolId),
              this.store.select(selectSelectedSchoolYear)
            ),
            filter(
              ([employeeId, schoolId, schoolYear]) =>
                !!employeeId && !!schoolId && !!schoolYear
            ),
            map(([employeeId, schoolId, schoolYear]) => {
              this.store.dispatch(
                EmployeeCompanyVacationsActions.loadEmployeeCompanyVacations({
                  employeeId,
                  schoolId,
                  schoolYear,
                })
              );
              return false;
            })
          );
        } else {
          return of(true);
        }
      }),
      filter((loaded) => !!loaded),
      first()
    );
  }
}
