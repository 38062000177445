import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmployeeWorkTimeReducerMapState } from '../../state/work-time.reducer-map';
export const selectEmployeeContractsFeatureSelector =
  createFeatureSelector<EmployeeWorkTimeReducerMapState>(
    'employee-work-time-reducer-map'
  );

// Load Employee Contracts
export const selectEmployeeContracts = createSelector(
  selectEmployeeContractsFeatureSelector,
  (state: EmployeeWorkTimeReducerMapState) => {
    return state?.employeeContracts.employeeContractsData;
  }
);

export const selectEmployeeContractsLoaded = createSelector(
  selectEmployeeContractsFeatureSelector,
  (state) => state?.employeeContracts.employeeContractsDataLoaded
);

export const selectEmployeeContractsError = createSelector(
  selectEmployeeContractsFeatureSelector,
  (state) => state.employeeContracts.employeeContractsDataError
);

// Select Selected Employee Contract
export const selectSelectedEmployeeContract = createSelector(
  selectEmployeeContractsFeatureSelector,
  (state) => state.employeeContracts.selectedEmployeeContract
);
