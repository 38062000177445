import { createAction, props } from '@ngrx/store';
import { ParentInformationCategory } from '../../interfaces/parent-information-category.interface';

// Load Parent Informations Categories
export const loadParentInformationCategories = createAction(
  '[ ParentInformationCategoriesResolver ] Load Parent Information Categories',
  props<{ schoolId: string; usersDefaultLanguageId: string }>()
);

export const loadParentInformationCategoriesSuccess = createAction(
  '[ ParentInformationCategoriesEffects ] Load Parent Information Categories Success',
  props<{ parentInformationCategories: ParentInformationCategory[] }>()
);

export const loadParentInformationCategoriesFailure = createAction(
  '[ ParentInformationCategoriesEffects ] Load Parent Information Categories Failed',
  props<{ error: string }>()
);

// Set Parent Information Category
export const setParentInformationCategory = createAction(
  '[ ParentInformationCategoriesMenu ] Set Parent Information Category',
  props<{ parentInformationCategoryId: string }>()
);

// Reset Parent Information Category
export const resetParentInformationCategory = createAction(
  '[ ParentInformationListContainer ] Reset Selected Parent Information Category'
);
