import { Pipe, PipeTransform } from '@angular/core';
import { convertMinutesInHoursAndMinutes } from '../utils/utils';

@Pipe({
  name: 'minToHoursAndMinutes',
})
export class MinToHoursAndMinutesPipe implements PipeTransform {
  transform(min: number, ...args: unknown[]): string {
    return convertMinutesInHoursAndMinutes(min);
  }
}
