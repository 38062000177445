import { createSelector, createFeatureSelector } from '@ngrx/store';
import { EmployeeWorkTimeReducerMapState } from '../../state/work-time.reducer-map';

export const selectEmployeeAbsencesFeatureSelector =
  createFeatureSelector<EmployeeWorkTimeReducerMapState>(
    'employee-work-time-reducer-map'
  );

// Select Fetched Employee  Absences
export const selectFetchedEmployeeAbsences = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.fetchedEmployeeAbsencesData
);

export const selectFetchedEmployeeAbsencesLoaded = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.fetchedEmployeeAbsencesDataLoaded
);

export const selectFetchedEmployeeAbsencesError = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.fetchedEmployeeAbsencesDataError
);

// Select Employee Absences
export const selectEmployeeAbsences = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.employeeAbsencesData
);

export const selectEmployeeAbsencesLoaded = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.employeeAbsencesDataLoaded
);

export const selectEmployeeAbsencesError = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.employeeAbsencesDataError
);

// Select Selected Employee  Absence
export const selectSelectedEmployeeAbsence = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.selectedEmployeeAbsence
);

// Select Employee Full Day Absences
export const selectEmployeeFullDayAbsences = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) =>
    state.employeeAbsences.employeeAbsencesData.filter(
      (employeeAbsence) =>
        employeeAbsence.isFullDayAbsence &&
        employeeAbsence.absenceTimeSlots.length === 0
    )
);

// Select Employee Full Day Absences with Time Slots
export const selectEmployeeFullDayAbsencesWithTimeSlots = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) =>
    state.employeeAbsences.employeeAbsencesData.filter(
      (employeeAbsence) =>
        employeeAbsence.isFullDayAbsence &&
        employeeAbsence.absenceTimeSlots.length > 0
    )
);

// Select Employee Intra Day Absences
export const selectEmployeeIntraDayAbsences = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) =>
    state.employeeAbsences.employeeAbsencesData.filter(
      (employeeAbsence) => !employeeAbsence.isFullDayAbsence
    )
);

// Full Day Absences in Date Range
// Select Employee Full Day Absences in Minutes
export const selectEmployeeFullDayAbsencesInMinutes = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.employeeFullDayAbsencesInDateRangeInMinutes
);

// Select Employee Full Day Absences in Days
export const selectEmployeeFullDayAbsencesInDays = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.employeeFullDayAbsencesDaysInDateRange
);

// Select Employee Full Day Absences by Category
export const selectEmployeeFullDayAbsencesByCategory = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.employeeFullDayAbsencesByCategory
);

// Select Employee Full Day Absences With Time Slots
// Select Employee Full Day Absences With Time Slots in Date Range
export const selectEmployeeFullDayAbsencesWithTimeSlotsInMinutes =
  createSelector(
    selectEmployeeAbsencesFeatureSelector,
    (state) =>
      state.employeeAbsences
        .employeeFullDayAbsencesWithTimeSlotsInDateRangeInMinutes
  );

// Select Employee Full Day Absences With Time Slots in Date Range
export const selectEmployeeFullDayAbsencesDaysWithTimeSlots = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) =>
    state.employeeAbsences.employeeFullDayAbsencesDaysWithTimeSlotsInDateRange
);

// Select Employee Full Day Absences With Time Slots by Category
export const selectEmployeeFullDayAbsencesWithTimeSlotsByCategory =
  createSelector(
    selectEmployeeAbsencesFeatureSelector,
    (state) =>
      state.employeeAbsences.employeeFullDayAbsencesWithTimeSlotsByCategory
  );

// Select Employee Intray Day Absences in Date Range
export const selectEmployeeIntraDayAbsencesInDateRangeInMinutes =
  createSelector(
    selectEmployeeAbsencesFeatureSelector,
    (state) =>
      state.employeeAbsences.employeeIntraDayAbsencesInDateRangeInMinutes
  );

// Select Employee Intray Day Absences in Date Range
export const selectEmployeeIntraDayAbsencesDaysInDateRange = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.employeeIntraDayAbsencesDaysInDateRange
);

// Select Employee Intray Day Absences by Category
export const selectEmployeeIntraDayAbsencesByCategory = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.employeeIntraDayAbsencesByCategory
);

// Employee Absences in School Year
// Full Day Absences in School Year
// Select Employee Full Day Absences in Minutes In School Year
export const selectEmployeeFullDayAbsencesInMinutesInSchoolYear =
  createSelector(
    selectEmployeeAbsencesFeatureSelector,
    (state) =>
      state.employeeAbsences.employeeFullDayAbsencesInMinutesInSchoolYear
  );

// Select Employee Full Day Absences in Days in School Year
export const selectEmployeeFullDayAbsencesInDaysInSchoolYear = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.employeeFullDayAbsencesDaysInSchoolYear
);

// Select Employee Full Day Absences by Category in School Year
export const selectEmployeeFullDayAbsencesByCategoryInSchoolYear =
  createSelector(
    selectEmployeeAbsencesFeatureSelector,
    (state) =>
      state.employeeAbsences.employeeFullDayAbsencesByCategoryInSchoolYear
  );

// Select Employee Full Day Absences With Time Slots
// Select Employee Full Day Absences With Time Slots in School Year
export const selectEmployeeFullDayAbsencesWithTimeSlotsInMinutesInSchoolYear =
  createSelector(
    selectEmployeeAbsencesFeatureSelector,
    (state) =>
      state.employeeAbsences
        .employeeFullDayAbsencesWithTimeSlotsInMinutesInSchoolYear
  );

// Select Employee Full Day Absences With Time Slots in School Year
export const selectEmployeeFullDayAbsencesDaysWithTimeSlotsInSchoolYear =
  createSelector(
    selectEmployeeAbsencesFeatureSelector,
    (state) =>
      state.employeeAbsences
        .employeeFullDayAbsencesDaysWithTimeSlotsInSchoolYear
  );

// Select Employee Full Day Absences With Time Slots by Category in School Year
export const selectEmployeeFullDayAbsencesWithTimeSlotsByCategoryInSchoolYear =
  createSelector(
    selectEmployeeAbsencesFeatureSelector,
    (state) =>
      state.employeeAbsences
        .employeeFullDayAbsencesWithTimeSlotsByCategoryInSchoolYear
  );

// Select Employee Intra Day Absences In Minutes in School Year
export const selectEmployeeIntraDayAbsencesInMinutesInSchoolYear =
  createSelector(
    selectEmployeeAbsencesFeatureSelector,
    (state) =>
      state.employeeAbsences.employeeIntraDayAbsencesInMinutesInSchoolYear
  );

// Select Employee Intray Day Absences in School Year
export const selectEmployeeIntraDayAbsencesDaysInSchoolYear = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.employeeIntraDayAbsencesDaysInSchoolYear
);

// Select Employee Intray Day Absences by Category In School Year
export const selectEmployeeIntraDayAbsencesByCategoryInSchoolYear =
  createSelector(
    selectEmployeeAbsencesFeatureSelector,
    (state) =>
      state.employeeAbsences.employeeIntraDayAbsencesByCategoryInSchoolYear
  );

// Employee Absences in Until Today
// Full Day Absences in Until Today
// Select Employee Full Day Absences in Minutes Until Today
export const selectEmployeeFullDayAbsencesInMinutesUntilToday = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.employeeFullDayAbsencesInMinutesUntilToday
);

// Select Employee Full Day Absences in Days Until Today
export const selectEmployeeFullDayAbsencesInDaysUntilToday = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.employeeFullDayAbsencesDaysUntilToday
);

// Select Employee Full Day Absences by Category Until Today
export const selectEmployeeFullDayAbsencesByCategoryUntilToday = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.employeeFullDayAbsencesByCategoryUntilToday
);

// Select Employee Full Day Absences With Time Slots
// Select Employee Full Day Absences With Time Slots Until Today
export const selectEmployeeFullDayAbsencesWithTimeSlotsInMinutesUntilToday =
  createSelector(
    selectEmployeeAbsencesFeatureSelector,
    (state) =>
      state.employeeAbsences
        .employeeFullDayAbsencesWithTimeSlotsInMinutesUntilToday
  );

// Select Employee Full Day Absences With Time Slots Until Today
export const selectEmployeeFullDayAbsencesDaysWithTimeSlotsUntilToday =
  createSelector(
    selectEmployeeAbsencesFeatureSelector,
    (state) =>
      state.employeeAbsences.employeeFullDayAbsencesDaysWithTimeSlotsUntilToday
  );

// Select Employee Full Day Absences With Time Slots by Category Until Today
export const selectEmployeeFullDayAbsencesWithTimeSlotsByCategoryUntilToday =
  createSelector(
    selectEmployeeAbsencesFeatureSelector,
    (state) =>
      state.employeeAbsences
        .employeeFullDayAbsencesWithTimeSlotsByCategoryUntilToday
  );

// Select Employee Intray Day Absences Until Today
export const selectEmployeeIntraDayAbsencesInDateRangeInMinutesUntilToday =
  createSelector(
    selectEmployeeAbsencesFeatureSelector,
    (state) =>
      state.employeeAbsences.employeeIntraDayAbsencesInMinutesUntilToday
  );

// Select Employee Intray Day Absences Until Today
export const selectEmployeeIntraDayAbsencesDaysUntilToday = createSelector(
  selectEmployeeAbsencesFeatureSelector,
  (state) => state.employeeAbsences.employeeIntraDayAbsencesDaysUntilToday
);

// Select Employee Intray Day Absences by Category
export const selectEmployeeIntraDayAbsencesByCategoryUntilToday =
  createSelector(
    selectEmployeeAbsencesFeatureSelector,
    (state) =>
      state.employeeAbsences.employeeIntraDayAbsencesByCategoryUntilToday
  );
