/* eslint-disable @typescript-eslint/naming-convention */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './components/login/login/login.component';

import { IonicModule } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { LoginContainerComponent } from './components/login/login-container/login-container.component';

import { StoreModule } from '@ngrx/store';
import { authReducer } from './state/auth/auth.reducer';
import { AuthEffects } from './state/auth/auth.effects';
import { EffectsModule } from '@ngrx/effects';
import { authMapReducer } from './auth-map.reducer';
import { UsersDefaultLanguageEffects } from './state/users-default-language/users-default-language.effects';
import { DefaultLanguageSelectionContainerComponent } from './components/default-language-selection/default-language-selection-container/default-language-selection-container.component';
import { DefaultLanguageSelectionComponent } from './components/default-language-selection/default-language-selection/default-language-selection.component';

@NgModule({
  declarations: [
    LoginContainerComponent,
    LoginComponent,
    DefaultLanguageSelectionContainerComponent,
    DefaultLanguageSelectionComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    AuthRoutingModule,
    StoreModule.forFeature('auth', authMapReducer),
    EffectsModule.forFeature([AuthEffects, UsersDefaultLanguageEffects]),
  ],
})
export class AuthModule {}
