import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthMapState } from '../../auth-map.reducer';

export const selectUsersDefaultLanguageFeatureSelector =
  createFeatureSelector<AuthMapState>('auth');

export const selectUsersDefaultLanguage = createSelector(
  selectUsersDefaultLanguageFeatureSelector,
  (state) => state.usersDefaultLanguage.usersDefaultLanguageData
);

export const selectUsersDefaultLanguageLoaded = createSelector(
  selectUsersDefaultLanguageFeatureSelector,
  (state) => state.usersDefaultLanguage.usersDefaultLanguageDataLoaded
);

export const selectUsersDefaultLanguageError = createSelector(
  selectUsersDefaultLanguageFeatureSelector,
  (state) => state.usersDefaultLanguage.usersDefaultLanguageDataError
);
