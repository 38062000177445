import { createAction, props } from '@ngrx/store';
import { Language } from '../../../pages/parents/parent-informations/interfaces/language.interface';

export const loadLanguages = createAction(
  '[ LanguagesResolver ] Load Languages',
  props<{ schoolId: string }>()
);

export const loadLanguagesSuccess = createAction(
  '[ LanguagesEffects ] Load Languages Success',
  props<{ languages: Language[] }>()
);

export const loadLanguagesFailure = createAction(
  '[ LanguagesEffects ] Load Languages Failed',
  props<{ error: string }>()
);
