import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  switchMap,
  withLatestFrom,
  tap,
  map,
  filter,
  first,
} from 'rxjs/operators';
import { selectSelectedSchoolYear } from '../../../../shared/state/school-years/school-years.selectors';
import { EmployeeAbsencesActions } from '../state/employee-absences-action-types';
import {
  selectEmployeeAbsencesLoaded,
  selectFetchedEmployeeAbsences,
  selectFetchedEmployeeAbsencesLoaded,
  selectSelectedEmployeeAbsence,
} from '../state/employee-absences.selectors';
import { selectUser } from 'src/app/pages/auth/state/auth/auth.selectors';

@Injectable({ providedIn: 'root' })
export class EmployeeAbsencesResolver {
  constructor(private store: Store) {}
  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(selectFetchedEmployeeAbsencesLoaded).pipe(
      switchMap((loaded) => {
        if (!loaded) {
          return this.store.select(selectUser).pipe(
            withLatestFrom(this.store.select(selectSelectedSchoolYear)),
            filter(([user, schoolYear]) => !!user && !!schoolYear),
            tap(([employee, schoolYear]) =>
              this.store.dispatch(
                EmployeeAbsencesActions.loadEmployeeAbsences({
                  employeeId: employee.id,
                  schoolYear,
                })
              )
            ),
            map(() => false)
          );
        } else {
          return of(true);
        }
      }),
      filter((loaded) => !!loaded),
      first()
    );
  }
}
