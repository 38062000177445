import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  switchMap,
  withLatestFrom,
  map,
  tap,
  filter,
  first,
} from 'rxjs/operators';

import { selectSelectedSchoolYear } from '../../../../shared/state/school-years/school-years.selectors';
import { CompanyVacationsActions } from '../state/company-vacations-action-types';
import { selectSchoolId } from '../../../auth/state/auth/auth.selectors';
import { SchoolYear } from '../../../../shared/interfaces/school-year.interface';
import {
  selectCompanyVacationsLoaded,
  selectFetchedCompanyVacations,
} from '../state/company-vacations.selectors';

@Injectable({ providedIn: 'root' })
export class CompanyVacationsResolver {
  constructor(private store: Store) {}
  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(selectFetchedCompanyVacations).pipe(
      switchMap((loaded) => {
        if (!loaded) {
          return this.store.select(selectSchoolId).pipe(
            withLatestFrom(this.store.select(selectSelectedSchoolYear)),
            filter(([schoolId, schoolYear]) => !!schoolId && !!schoolYear),
            map(([schoolId, schoolYear]) =>
              this.store.dispatch(
                CompanyVacationsActions.loadCompanyVacations({
                  schoolId,
                  schoolYear,
                })
              )
            ),
            map(() => false)
          );
        } else {
          return of(true);
        }
      }),
      filter((loaded) => !!loaded),
      first()
    );
  }
}
