import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SchoolService } from '../../services/school/school.service';
import { SchoolActions } from './school-action-types';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SchoolEffects {
  loadSchool$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchoolActions.findSchool),
      switchMap((action) =>
        this.schoolService.loadSchool(action.schoolUrl).pipe(
          map((school) => SchoolActions.findSchoolSuccess({ school })),
          catchError((error) => of(SchoolActions.findSchoolFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private schoolService: SchoolService
  ) {}
}
