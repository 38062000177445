/* eslint-disable @typescript-eslint/naming-convention */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './components/header/header.component';
import { ContentHeaderComponent } from './components/content-header/content-header.component';

import { MinToHoursAndMinutesPipe } from './pipes/min-to-hours-and-minutes.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { LoadingComponent } from './components/loading/loading.component';
import { StoreModule } from '@ngrx/store';
import { sharedReducerMap } from './state/shared.reducer-map';

import { MinToDaysHoursMinutesPipe } from './pipes/min-to-days-hours-minutes.pipe';
import { BlankSlateComponent } from './components/blank-slate/blank-slate.component';
import { IonSelectComponent } from './components/ion-select/ion-select.component';
import { EffectsModule } from '@ngrx/effects';
import { LastSchoolYearsEffects } from './state/school-years/school-years.effects';
import { SelectSchoolYearComponent } from './components/select-school-year/select-school-year.component';
import { SchoolEffects } from './state/school/school.effects';
import { LanguagesEffects } from './state/languages/languages.effects';
import { LanguageSelectionComponent } from './components/language-selection/language-selection.component';
import { ContentHeaderLogoComponent } from './components/content-header-logo/content-header-logo.component';
import { TranslateModule } from '@ngx-translate/core';
import { ParentInformationsCategoriesMenuComponent } from './components/parent-informations-categories-menu/parent-informations-categories-menu.component';
import { RouterModule } from '@angular/router';
import { SelectTimeRangeComponent } from './components/select-time-range/select-time-range.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { LuxonModule } from 'luxon-angular';
import { HolidayEffects } from './holidays/state/holidays.effects';
import { GreetingComponent } from './components/greeting/greeting.component';
import { SubtitleComponent } from './components/subtitle/subtitle.component';
import { TitleComponent } from './components/title/title.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { CardComponent } from './components/card/card.component';

@NgModule({
  declarations: [
    HeaderComponent,
    ContentHeaderComponent,
    MinToHoursAndMinutesPipe,
    SearchFilterPipe,
    LoadingComponent,
    MinToDaysHoursMinutesPipe,
    BlankSlateComponent,
    IonSelectComponent,
    SelectSchoolYearComponent,
    LanguageSelectionComponent,
    ContentHeaderLogoComponent,
    ParentInformationsCategoriesMenuComponent,
    SelectTimeRangeComponent,
    TabsComponent,
    GreetingComponent,
    SubtitleComponent,
    TitleComponent,
    ModalHeaderComponent,
    CardComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    LuxonModule,
    StoreModule.forFeature('shared', sharedReducerMap),
    EffectsModule.forFeature([
      LastSchoolYearsEffects,
      SchoolEffects,
      LanguagesEffects,
      HolidayEffects,
    ]),
    TranslateModule,
  ],
  exports: [
    HeaderComponent,
    ContentHeaderComponent,
    LoadingComponent,
    ReactiveFormsModule,
    FormsModule,
    LuxonModule,
    MinToHoursAndMinutesPipe,
    SearchFilterPipe,
    MinToDaysHoursMinutesPipe,
    BlankSlateComponent,
    IonSelectComponent,
    SelectSchoolYearComponent,
    LanguageSelectionComponent,
    ContentHeaderLogoComponent,
    TranslateModule,
    ParentInformationsCategoriesMenuComponent,
    SelectTimeRangeComponent,
    TabsComponent,
    GreetingComponent,
    SubtitleComponent,
    TitleComponent,
    ModalHeaderComponent,
    CardComponent,
  ],
})
export class SharedModule {}
