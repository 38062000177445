import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmployeeWorkTimeReducerMapState } from '../../state/work-time.reducer-map';

export const selectTransferredBalancesFeatureSelector =
  createFeatureSelector<EmployeeWorkTimeReducerMapState>(
    'employee-work-time-reducer-map'
  );

// Fetched TransferredBalances
export const selectFetchedTransferredBalances = createSelector(
  selectTransferredBalancesFeatureSelector,
  (state) => state.transferredBalances.fetchedTransferredBalances
);

export const selectFetchedTransferredBalancesLoaded = createSelector(
  selectTransferredBalancesFeatureSelector,
  (state) => state.transferredBalances.fetchedTransferredBalancesLoaded
);

export const selectFetchedTransferredBalancesError = createSelector(
  selectTransferredBalancesFeatureSelector,
  (state) => state.transferredBalances.fetchedTransferredBalancesError
);

// TransferredBalances with Data
export const selectTransferredBalances = createSelector(
  selectTransferredBalancesFeatureSelector,
  (state) => state.transferredBalances.transferredBalancesData
);

export const selectTransferredBalancesLoaded = createSelector(
  selectTransferredBalancesFeatureSelector,
  (state) => state.transferredBalances.transferredBalancesLoaded
);

export const selectTransferredBalancesError = createSelector(
  selectTransferredBalancesFeatureSelector,
  (state) => state.transferredBalances.transferredBalancesError
);

// Selected TransferredBalance
export const selectSelectedTransferredBalance = createSelector(
  selectTransferredBalancesFeatureSelector,
  (state) => state.transferredBalances.selectedTransferredBalance
);

// TransferredBalances in Minutes
export const selectTransferredBalancesInMinutes = createSelector(
  selectTransferredBalancesFeatureSelector,
  (state) => state.transferredBalances.transferredBalancesInDateRangeInMinutes
);
