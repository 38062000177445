import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmployeeWorkTimeReducerMapState } from '../../state/work-time.reducer-map';

export const selectWorkTimeDataState =
  createFeatureSelector<EmployeeWorkTimeReducerMapState>(
    'employee-work-time-reducer-map'
  );

// Employee Work Time
export const selectEmployeeWorkTime = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeData
);

export const selectEmployeeWorkTimeLoaded = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeDataLoaded
);

export const selectEmployeeWorkTimeError = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeDataError
);

// Select Selected Employee Work Time
export const selectSelectedEmployeeWorkTime = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.selectedEmployeeWorkTime
);

// Work Time Calculations in Date Range
export const selectEmployeeWorkTimeInContracts = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeInContracts
);

export const selectEmployeeWorkTimeOutsideContracts = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeOutsideContracts
);

export const selectEmployeeWorkTimeWorkedInMinutes = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeWorkedInMinutes
);

export const selectEmployeeWorkTimeToWorkInMinutes = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeToWorkInMinutes
);

export const selectEmployeeWorkTimeOnWeekends = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeOnWeekends
);

export const selectEmployeeWorkTimeWithMoreThan10Hours = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeWithMoreThan10Hours
);

export const selectEmployeeWorkTimeWithoutBreakTime = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeWithoutBreakTime
);

export const selectEmployeeWorkTimeCalculationsError = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeCalculationsError
);

// Work Time Calculations Until Today
export const selectEmployeeWorkTimeInContractsUntilToday = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeInContractsUntilToday
);

export const selectEmployeeWorkTimeOutsideContractsUntilToday = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeOutsideContractsUntilToday
);

export const selectEmployeeWorkTimeWorkedInMinutesUntilToday = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeWorkedInMinutesUntilToday
);

export const selectEmployeeWorkTimeToWorkInMinutesUntilToday = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeToWorkInMinutesUntilToday
);

export const selectEmployeeWorkTimeOnWeekendsUntilToday = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeOnWeekendsUntilToday
);

export const selectEmployeeWorkTimeWithMoreThan10HoursUntilToday =
  createSelector(
    selectWorkTimeDataState,
    (state) =>
      state.employeeWorkTime.employeeWorkTimeWithMoreThan10HoursUntilToday
  );

export const selectEmployeeWorkTimeWithoutBreakTimeUntilToday = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeWithoutBreakTimeUntilToday
);

export const selectEmployeeWorkTimeCalculationsUntilTodayError = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeCalculationsUntilTodayError
);

// Work Time Calculations in School Year
export const selectEmployeeWorkTimeInContractsInSchoolYear = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeInContractsInSchoolYear
);

export const selectEmployeeWorkTimeOutsideContractsInSchoolYear =
  createSelector(
    selectWorkTimeDataState,
    (state) =>
      state.employeeWorkTime.employeeWorkTimeOutsideContractsInSchoolYear
  );

export const selectEmployeeWorkTimeWorkedInMinutesInSchoolYear = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeWorkedInMinutesInSchoolYear
);

export const selectEmployeeWorkTimeToWorkInMinutesInSchoolYear = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeToWorkInMinutesInSchoolYear
);

export const selectEmployeeWorkTimeOnWeekendsInSchoolYear = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeOnWeekendsInSchoolYear
);

export const selectEmployeeWorkTimeWithMoreThan10HoursInSchoolYear =
  createSelector(
    selectWorkTimeDataState,
    (state) =>
      state.employeeWorkTime.employeeWorkTimeWithMoreThan10HoursInSchoolYear
  );

export const selectEmployeeWorkTimeWithoutBreakTimeInSchoolYear =
  createSelector(
    selectWorkTimeDataState,
    (state) =>
      state.employeeWorkTime.employeeWorkTimeWithoutBreakTimeInSchoolYear
  );

export const selectEmployeeWorkTimeCalculationsInSchoolYearError =
  createSelector(
    selectWorkTimeDataState,
    (state) =>
      state.employeeWorkTime.employeeWorkTimeCalculationsInSchoolYearError
  );

// Work Time Days To Work
export const selectEmployeeWorkTimeDaysToWorkInSchoolYear = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeDaysToWorkInSchoolYear
);

export const selectEmployeeWorkTimeDaysToWorkUntilToday = createSelector(
  selectWorkTimeDataState,
  (state) => state.employeeWorkTime.employeeWorkTimeDaysToWorkUntilToday
);
