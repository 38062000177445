import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { LoadingActions } from 'src/app/shared/state/loading/loading-action-types';
import { AuthService } from '../../services/auth.service';
import { selectUsersDefaultLanguageLoaded } from '../users-default-language/users-default-language.selectors';
import { AuthActions } from './auth-action-types';

@Injectable({ providedIn: 'root' })
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap((action) => {
        this.store.dispatch(LoadingActions.loadingTrue());
        return this.authService.login(action.credentials).pipe(
          map((user) => {
            this.store.dispatch(LoadingActions.loadingFalse());
            return AuthActions.loginSuccess({ user });
          }),
          catchError((error) => {
            this.store.dispatch(LoadingActions.loadingFalse());
            return of(AuthActions.loginFailure({ error }));
          })
        );
      })
    )
  );

  saveEmailInLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.saveEmailInLocalStorage),
        tap((action) => localStorage.setItem('email', action.email))
      ),
    { dispatch: false }
  );

  // routingAfterLogin$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.loginSuccess),
  //     tap((userData) => {
  //       const user = userData.user;
  //       const { roles } = user;

  //       const workTime = !!roles?.some(
  //         (role) =>
  //           role === 'admin' ||
  //           role === 'employee' ||
  //           role === 'carer' ||
  //           role === 'administration' ||
  //           role === 'hr'
  //       );

  //       console.log(workTime);

  //       if (workTime) {
  //         console.log('workTime');
  //         this.router.navigate(['/employee-work-time']);
  //       } else {
  //         console.log('not workTime');
  //         this.store
  //           .select(selectUsersDefaultLanguageLoaded)
  //           .pipe(
  //             filter((loaded) => !!loaded),
  //             tap((loaded) => {
  //               if (loaded) {
  //                 this.router.navigate(['/parents/tabs/home']);
  //               } else {
  //                 this.router.navigate(['/parents/language-selection']);
  //               }
  //             }),
  //             first()
  //           )
  //           .subscribe();
  //       }
  //     }),
  //     first()
  //   )
  // );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private store: Store
  ) {}
}
