import { Injectable, inject } from '@angular/core';
import { Auth, authState, getIdTokenResult } from '@angular/fire/auth';
import {
  DocumentReference,
  Firestore,
  doc,
  docData,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { AlertController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable, from, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthActions } from 'src/app/pages/auth/state/auth/auth-action-types';
import { User } from '../interfaces/user.interface';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private auth: Auth,
    private firestore: Firestore,
    private store: Store,
    private router: Router,
    private alertController: AlertController
  ) {}

  getUserId(): Observable<string> {
    return from(this.auth.currentUser.uid);
  }

  login(credentials: { email: string; password: string }) {
    return from(
      signInWithEmailAndPassword(
        this.auth,
        credentials.email,
        credentials.password
      )
    ).pipe(
      switchMap(() => {
        return authState(this.auth).pipe(
          switchMap((user) => {
            return from(getIdTokenResult(user)).pipe(
              map((idTokenResult) => idTokenResult.claims)
            );
          }),
          switchMap((claims) => {
            const userDocRef = doc(
              this.firestore,
              `users/${claims.user_id}`
            ) as DocumentReference<User>;
            return docData(userDocRef, { idField: 'id' }).pipe(
              map((user: User) => {
                const currentUser: User = {
                  id: user.id,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: claims.email as string,
                  schoolId: user.employedBy || user.parentOfSchool,
                  isActive: user.isActive,
                  title: user.title,
                  gender: user.gender,
                  roles: user.roles,
                  imageUrl: user.imageUrl,
                };
                return currentUser;
              }),
              tap((user) => {
                const { roles } = user;
                const workTime = !!roles?.some(
                  (role) =>
                    role === 'admin' ||
                    role === 'employee' ||
                    role === 'carer' ||
                    role === 'administration' ||
                    role === 'hr'
                );
                console.log('isEmployee', workTime);
                if (workTime) {
                  this.router.navigate(['employee-work-time']);
                } else {
                  this.router.navigate(['parents/language-selection']);
                }
                // this.store.dispatch(AuthActions.loginSuccess({ user }));
              }),

              catchError((error) => {
                console.log(error);
                return throwError(error);
              })
            );
          })
        );
      })
    );
  }

  logout(): void {
    // this.auth.signOut();
    // this.store.dispatch(AuthActions.logout());
    window.location.reload();
    this.router.navigate(['login']);
  }

  saveUserInLocalStorage(user: User) {
    localStorage.setItem('auth', JSON.stringify(user));
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Upps...',
      message:
        'Passwort oder E-Mail waren nicht korrekt....versuch es nochmals!',
      buttons: ['OK'],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }
}
