import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
})
export class CardComponent {
  @Input() cardWidth: number;
  @Input() cardHeight: number;
  @Input() backgroundColor: string;
  @Input() shadowColor: string;
  @Input() icon: string | number;
  @Input() iconColor: string;
  @Input() iconShadowColor: string;
  @Input() textColor: string;
  @Input() titleOne: string;
  @Input() titleTwo: string;
  @Input() valueOne: string;
  @Input() valueTwo: string | number;
  @Input() chartValueOne: string;
  @Input() chartValueTwo: string;
  @Input() chartPercentage: number;

  isNumber(val): boolean {
    return typeof val === 'number';
  }
}
