import { Language } from '../../../parents/parent-informations/interfaces/language.interface';
import { createReducer, on } from '@ngrx/store';
import { UsersDefaultLanguageActions } from 'src/app/pages/auth/state/users-default-language/users-default-language-action-types';

export interface UsersDefaultLanguageState {
  usersDefaultLanguageData: Language;
  usersDefaultLanguageDataLoaded: boolean;
  usersDefaultLanguageDataError: string;
}

const initialState: UsersDefaultLanguageState = {
  usersDefaultLanguageData: undefined,
  usersDefaultLanguageDataLoaded: false,
  usersDefaultLanguageDataError: '',
};

export const usersDefaultLanguageReducer =
  createReducer<UsersDefaultLanguageState>(
    initialState,

    on(
      UsersDefaultLanguageActions.loadLanguageFromLocalStorageSuccess,
      (state, action): UsersDefaultLanguageState => ({
        ...state,
        usersDefaultLanguageData: action.language,
        usersDefaultLanguageDataLoaded: true,
        usersDefaultLanguageDataError: '',
      })
    ),
    on(
      UsersDefaultLanguageActions.loadLanguageFromLocalStorageFailure,
      (state, action): UsersDefaultLanguageState => ({
        ...state,
        usersDefaultLanguageData: undefined,
        usersDefaultLanguageDataLoaded: false,
        usersDefaultLanguageDataError: action.error,
      })
    ),

    on(
      UsersDefaultLanguageActions.setUsersDefaultLanguageInLocalStorageSuccess,
      (state, action): UsersDefaultLanguageState => ({
        ...state,
        usersDefaultLanguageData: action.language,
        usersDefaultLanguageDataLoaded: true,
        usersDefaultLanguageDataError: '',
      })
    )
  );
