import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LanguagesService } from '../../services/languages/languages.service';
import { LanguagesActions } from './languages-action-types';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthActions } from 'src/app/pages/auth/state/auth/auth-action-types';
import { Store } from '@ngrx/store';
import { selectUser } from '../../../pages/auth/state/auth/auth.selectors';
import { Language } from 'src/app/pages/parents/parent-informations/interfaces/language.interface';

@Injectable({ providedIn: 'root' })
export class LanguagesEffects {
  listenForLoginSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(AuthActions.loginSuccess),

      switchMap(() =>
        this.store.select(selectUser).pipe(
          map((user) => {
            return LanguagesActions.loadLanguages({
              schoolId: user.schoolId,
            });
          })
        )
      )
    )
  );

  loadLanguages$ = createEffect(() =>
    this.actions.pipe(
      ofType(LanguagesActions.loadLanguages),
      switchMap((action) =>
        this.languagesService.loadLanguages(action.schoolId).pipe(
          map((languages: Language[]) =>
            LanguagesActions.loadLanguagesSuccess({ languages })
          ),
          catchError((error) =>
            of(LanguagesActions.loadLanguagesFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions: Actions,
    private languagesService: LanguagesService,
    private store: Store
  ) {}
}
