import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Language } from 'src/app/pages/parents/parent-informations/interfaces/language.interface';
import { selectLanguages } from '../../../../../shared/state/languages/languages.selectors';
import { ModalController } from '@ionic/angular';
import { UsersDefaultLanguageActions } from 'src/app/pages/auth/state/users-default-language/users-default-language-action-types';
import { selectUsersDefaultLanguageLoaded } from '../../../state/users-default-language/users-default-language.selectors';
import { tap, first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default-language-selection-container',
  templateUrl: './default-language-selection-container.component.html',
  styleUrls: ['./default-language-selection-container.component.scss'],
})
export class DefaultLanguageSelectionContainerComponent implements OnInit {
  languages$: Observable<Language[]>;
  constructor(private store: Store, private router: Router) {}

  ngOnInit() {
    this.languages$ = this.store.select(selectLanguages);
  }

  selectLanguage(language: Language) {
    this.store.dispatch(
      UsersDefaultLanguageActions.setUsersDefaultLanguageInLocalStorage({
        language,
      })
    );
    this.router.navigate(['/parents/tabs/home']);
  }
}
