import { createReducer, on } from '@ngrx/store';
import { LoadingActions } from './loading-action-types';

export interface LoadingState {
  loading: boolean;
}

const initialState: LoadingState = {
  loading: false,
};

export const loadingReducer = createReducer<LoadingState>(
  initialState,

  on(
    LoadingActions.loadingTrue,
    (state, action): LoadingState => ({
      ...state,
      loading: true,
    })
  ),

  on(
    LoadingActions.loadingFalse,
    (state, action): LoadingState => ({
      ...state,
      loading: false,
    })
  )
);
