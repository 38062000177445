import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedState } from '../../state/shared.reducer-map';
export const selectHolidaysFeatureSelector =
  createFeatureSelector<SharedState>('shared');

// Holidays
export const selectHolidays = createSelector(
  selectHolidaysFeatureSelector,
  (state) => state.holidays.holidaysData
);

export const selectHolidaysLoaded = createSelector(
  selectHolidaysFeatureSelector,
  (state) => state.holidays.holidaysLoaded
);

export const selectHolidaysError = createSelector(
  selectHolidaysFeatureSelector,
  (state) => state.holidays.holidaysError
);
