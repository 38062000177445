import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { TransferredBalancesActions } from '../state/transferred-balances-action-types';
import { selectFetchedTransferredBalancesLoaded } from '../state/transferred-balances.selectors';
import { selectUser } from 'src/app/pages/auth/state/auth/auth.selectors';

@Injectable({ providedIn: 'root' })
export class TransferredBalancesResolver implements Resolve<boolean> {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(selectFetchedTransferredBalancesLoaded).pipe(
      switchMap((loaded) => {
        if (!loaded) {
          return this.store.select(selectUser).pipe(
            tap((employee) =>
              this.store.dispatch(
                TransferredBalancesActions.loadTransferredBalances({
                  employeeId: employee.id,
                })
              )
            ),
            map(() => false)
          );
        } else {
          return of(true);
        }
      }),
      filter((loaded) => !!loaded),
      first()
    );
  }
}
