import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Language } from '../../parents/parent-informations/interfaces/language.interface';
import { Observable, of } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class UsersDefaultLanguageService {
  selectedLanguageId: string;
  constructor(private router: Router) {}

  setUsersDefaultLanguageInLocalStorage(
    language: Language
  ): Observable<Language> {
    localStorage.setItem('colibriDefaultLanguageId', language.id);
    return of(language);
  }

  loadUsersDefaultLanguage(languages: Language[]): Observable<Language> {
    const languageIdFromLocalStorage = localStorage.getItem(
      'colibriDefaultLanguageId'
    );

    if (languageIdFromLocalStorage) {
      return of(
        languages.find((language) => language.id === languageIdFromLocalStorage)
      );
    } else {
      return of({ id: '123', name: 'no Language', isActive: true });
    }
  }
}
