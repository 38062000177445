import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthActions } from 'src/app/pages/auth/state/auth/auth-action-types';
import { LanguagesActions } from 'src/app/shared/state/languages/languages-action-types';
import {
  switchMap,
  withLatestFrom,
  map,
  catchError,
  tap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectUser } from '../auth/auth.selectors';
import { UsersDefaultLanguageActions } from './users-default-language-action-types';

import { UsersDefaultLanguageService } from '../../services/users-default-language.service';
import { pipe, of, throwError } from 'rxjs';
import { selectLanguages } from '../../../../shared/state/languages/languages.selectors';
import { Language } from '../../../parents/parent-informations/interfaces/language.interface';
import { ModalController } from '@ionic/angular';
import { DefaultLanguageSelectionContainerComponent } from '../../components/default-language-selection/default-language-selection-container/default-language-selection-container.component';
import { first, filter } from 'rxjs/operators';
import { User } from '../../interfaces/user.interface';
import { Router } from '@angular/router';
import { loadLanguagesSuccess } from '../../../../shared/state/languages/languages.actions';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class UsersDefaultLanguageEffects {
  // listenForLoginSuccess$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.loginSuccess, LanguagesActions.loadLanguagesSuccess),

  //     switchMap(() =>
  //       this.store.select(selectLanguages).pipe(
  //         withLatestFrom(this.store.select(selectUser)),
  //         filter(([languages, user]) => !!languages),
  //         map(([languages, user]) => {
  //           return UsersDefaultLanguageActions.loadUsersLanguageFromLocalStorage(
  //             {
  //               languages,
  //             }
  //           );
  //         })
  //       )
  //     )
  //   )
  // );

  // loadUsersDefaultLanguage$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UsersDefaultLanguageActions.loadUsersLanguageFromLocalStorage),

  //     switchMap((action) =>
  //       this.usersDefaultLanguageService
  //         .loadUsersDefaultLanguage(action.languages)
  //         .pipe(
  //           map((language) => {
  //             if (language.id === '123') {
  //               this.router.navigate(['/parents/language-selection']);
  //             } else {
  //               this.router.navigate(['/parents/tabs/home']);
  //               this.translate.use(language.languageCode);

  //               return UsersDefaultLanguageActions.loadLanguageFromLocalStorageSuccess(
  //                 {
  //                   language,
  //                 }
  //               );
  //             }
  //           }),
  //           catchError((error) =>
  //             of(
  //               UsersDefaultLanguageActions.loadLanguageFromLocalStorageFailure(
  //                 { error }
  //               )
  //             )
  //           )
  //         )
  //     )
  //   )
  // );

  setUsersDefaultLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDefaultLanguageActions.setUsersDefaultLanguageInLocalStorage),
      switchMap((action) =>
        this.usersDefaultLanguageService
          .setUsersDefaultLanguageInLocalStorage(action.language)
          .pipe(
            map((language) => {
              this.translate.use(language.languageCode);
              this.router.navigate(['/parents/tabs/home']);
              return UsersDefaultLanguageActions.setUsersDefaultLanguageInLocalStorageSuccess(
                { language }
              );
            }),
            catchError((error) =>
              of(
                UsersDefaultLanguageActions.setUsersDefaultLanguageInLocalStorageFailure(
                  { error }
                )
              )
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private usersDefaultLanguageService: UsersDefaultLanguageService,
    private modalController: ModalController,
    private router: Router,
    private translate: TranslateService
  ) {}
}
