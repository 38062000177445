import { createAction, props } from '@ngrx/store';
import { SchoolYear } from 'src/app/shared/interfaces/school-year.interface';
import { EmployeeCompanyVacation } from '../interfaces/employee-company-vacation.interface';
import { CompanyVacation } from '../../company-vacations/interfaces/company-vacation.interface';
import { Holiday } from '../../interfaces/holiday.interface';
import { EmployeeContract } from '../../employee-contracts/interfaces/employee-contract.interface';

// Load Employee Company Vacations
export const loadEmployeeCompanyVacations = createAction(
  '[ EmployeeCompanyVacationsResolver ] Load Employee Company Vacations',
  props<{ employeeId: string; schoolId: string; schoolYear: SchoolYear }>()
);

export const loadEmployeeCompanyVacationsSuccess = createAction(
  '[ EmployeeCompanyVacationsEffects ] Load Employee Company Vacations Success',
  props<{ employeeCompanyVacations: EmployeeCompanyVacation[] }>()
);

export const loadEmployeeCompanyVacationsFailure = createAction(
  '[ EmployeeCompanyVacationsEffects ] Load Employee Company Vacations Failed',
  props<{ error: string }>()
);

// Load Employee Company Vacations
export const addDataToEmployeeCompanyVacations = createAction(
  '[ EmployeeCompanyVacationsResolver ] Add Data to Employee Company Vacations',
  props<{
    employeeCompanyVacations: EmployeeCompanyVacation[];
    companyVacations: CompanyVacation[];
    holidays: Holiday[];
  }>()
);

export const addDataToEmployeeCompanyVacationsSuccess = createAction(
  '[ EmployeeCompanyVacationsEffects ] Add Data to Employee Company Vacations Success',
  props<{ employeeCompanyVacations: EmployeeCompanyVacation[] }>()
);

export const addDataToEmployeeCompanyVacationsFailure = createAction(
  '[ EmployeeCompanyVacationsEffects ] Add Data to Employee Company Vacations Failed',
  props<{ error: string }>()
);

// Calculate Employee Company Vacations in Date Range
export const calculateEmployeeCompanyVacationsInDateRange = createAction(
  '[ EmployeeCompanyVacationsPage ] Calculate Employee Company Vacations in Date Range',
  props<{
    startDate: Date;
    endDate: Date;
    employeeCompanyVacations: EmployeeCompanyVacation[];
    holidays: Holiday[];
    employeeContracts: EmployeeContract[];
  }>()
);

export const calculateEmployeeCompanyVacationsInDateRangeSuccess = createAction(
  '[ EmployeeCompanyVacationsEffects ] Calculate Employee Company Vacations in Date Range Success',
  props<{
    employeeCompanyVacationsInMinutes: number;
    employeeCompanyVacationsDays: number;
  }>()
);

export const calculateEmployeeCompanyVacationsInDateRangeFailure = createAction(
  '[ EmployeeCompanyVacationsEffects ] Calculate Employee Company Vacations in Date Range Failed',
  props<{ error: string }>()
);

// Calculate Employee Company Vacations Until Today
export const calculateEmployeeCompanyVacationsUntilToday = createAction(
  '[ EmployeeCompanyVacationsEffects ] Calculate Employee Company Vacations Until Today',
  props<{
    startDate: Date;
    endDate: Date;
    employeeCompanyVacations: EmployeeCompanyVacation[];
    holidays: Holiday[];
    employeeContracts: EmployeeContract[];
  }>()
);

export const calculateEmployeeCompanyVacationsUntilTodaySuccess = createAction(
  '[ EmployeeCompanyVacationsEffects ] Calculate Employee Company Vacations Until Today Success',
  props<{
    employeeCompanyVacationsInMinutesUntilToday: number;
    employeeCompanyVacationsDaysUntilToday: number;
  }>()
);

export const calculateEmployeeCompanyVacationsUntilTodayFailure = createAction(
  '[ EmployeeCompanyVacationsEffects ] Calculate Employee Company Vacations Until Today Failed',
  props<{ error: string }>()
);

// Calculate Employee Company Vacations In School Year
export const calculateEmployeeCompanyVacationsInSchoolYear = createAction(
  '[ EmployeeCompanyVacationsEffects ] Calculate Employee Company Vacations In School Year',
  props<{
    startDate: Date;
    endDate: Date;
    employeeCompanyVacations: EmployeeCompanyVacation[];
    holidays: Holiday[];
    employeeContracts: EmployeeContract[];
  }>()
);

export const calculateEmployeeCompanyVacationsInSchoolYearSuccess =
  createAction(
    '[ EmployeeCompanyVacationsEffects ] Calculate Employee Company Vacations In School Year Success',
    props<{
      employeeCompanyVacationsInMinutesInSchoolYear: number;
      employeeCompanyVacationsDaysInSchoolYear: number;
    }>()
  );

export const calculateEmployeeCompanyVacationsInSchoolYearFailure =
  createAction(
    '[ EmployeeCompanyVacationsEffects ] Calculate Employee Company Vacations In School Year Failed',
    props<{ error: string }>()
  );
