import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { selectEmployeePaidOvertimeLoaded } from '../state/employee-paid-overtime.selectors';
import {
  filter,
  first,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EmployeePaidOvertimeActions } from '../state/employee-paid-overtime-action-types';
import { selectUser } from 'src/app/pages/auth/state/auth/auth.selectors';
import { selectDateRangeDates } from '../../state/work-time-utils/work-time-utils.selectors';

@Injectable({ providedIn: 'root' })
export class EmployeePaidOvertimeResolver implements Resolve<boolean> {
  constructor(private store: Store) {}
  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(selectEmployeePaidOvertimeLoaded).pipe(
      switchMap((loaded) => {
        if (!loaded) {
          return this.store.select(selectUser).pipe(
            withLatestFrom(this.store.select(selectDateRangeDates)),
            filter(
              ([employee, dateRangeDates]) =>
                !!employee &&
                !!dateRangeDates.startDate &&
                !!dateRangeDates.endDate
            ),
            tap(([employee, dateRangeDates]) =>
              this.store.dispatch(
                EmployeePaidOvertimeActions.loadEmployeePaidOvertime({
                  employeeId: employee.id,
                  startDate: dateRangeDates.startDate,
                  endDate: dateRangeDates.endDate,
                })
              )
            ),
            map(() => false)
          );
        } else {
          return of(true);
        }
      }),
      filter((loaded) => !!loaded),
      first()
    );
  }
}
