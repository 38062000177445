import { createAction, props } from '@ngrx/store';
import { EmployeePaidOvertime } from '../interfaces/employee-paid-overtime.interface';

//
export const loadEmployeePaidOvertime = createAction(
  '[EmployeePaidOvertimeResolver] Load Employee Paid Overtime',
  props<{ employeeId: string; startDate: Date; endDate: Date }>()
);

export const loadEmployeePaidOvertimeSuccess = createAction(
  '[EmployeePaidOvertimeEffect] Load Employee Paid Overtime Success',
  props<{ employeePaidOvertime: EmployeePaidOvertime[] }>()
);

export const loadEmployeePaidOvertimeFailure = createAction(
  '[EmployeePaidOvertimeEffect] Load Employee Paid Overtime Failure',
  props<{ error: string }>()
);

// Select Employee Paid Overtime
export const selectEmployeePaidOvertime = createAction(
  '[EmployeePaidOvertimeListContainer] Select Employee Paid Overtime',
  props<{ employeePaidOvertime: EmployeePaidOvertime }>()
);

// Add Employee Paid Overtime
export const addEmployeePaidOvertime = createAction(
  '[AddUpdateEmployeePaidOvertimeContainer] Add Employee Paid Overtime',
  props<{ employeePaidOvertime: EmployeePaidOvertime }>()
);

export const addEmployeePaidOvertimeSuccess = createAction(
  '[AddEmployeePaidOvertimeEffect] Add Employee Paid Overtime Success',
  props<{ employeePaidOvertime: EmployeePaidOvertime }>()
);

export const addEmployeePaidOvertimeFailure = createAction(
  '[AddEmployeePaidOvertimeEffect] Add Employee Paid Overtime Failure',
  props<{ error: string }>()
);

// Update Employee Paid Overtime

export const updateEmployeePaidOvertime = createAction(
  '[AddUpdateEmployeePaidOvertimeContainer] Update Employee Paid Overtime',
  props<{ employeePaidOvertime: EmployeePaidOvertime }>()
);

export const updateEmployeePaidOvertimeSuccess = createAction(
  '[UpdateEmployeePaidOvertimeEffect] Update Employee Paid Overtime Success',
  props<{ employeePaidOvertime: EmployeePaidOvertime }>()
);

export const updateEmployeePaidOvertimeFailure = createAction(
  '[UpdateEmployeePaidOvertimeEffect] Update Employee Paid Overtime Failure',
  props<{ error: string }>()
);

// Delete Employee Paid Overtime
export const deleteEmployeePaidOvertime = createAction(
  '[EmployeePaidOvertimeListContainer] Delete Employee Paid Overtime',
  props<{ employeePaidOvertime: EmployeePaidOvertime }>()
);

export const deleteEmployeePaidOvertimeSuccess = createAction(
  '[DeleteEmployeePaidOvertimeEffect] Delete Employee Paid Overtime Success',
  props<{ employeePaidOvertime: EmployeePaidOvertime }>()
);

export const deleteEmployeePaidOvertimeFailure = createAction(
  '[DeleteEmployeePaidOvertimeEffect] Delete Employee Paid Overtime Failure',
  props<{ error: string }>()
);

// Calculate Employee Paid Overtime in Date Range
export const calculateEmployeePaidOvertimeInDateRange = createAction(
  '[EmployeePaidOvertimeEffects] Calculate Employee Paid Overtime In Date Range',
  props<{
    employeePaidOvertime: EmployeePaidOvertime[];
    startDate: Date;
    endDate: Date;
  }>()
);

export const calculateEmployeePaidOvertimeInDateRangeSuccess = createAction(
  '[EmployeePaidOvertimeEffects] Calculate Employee Paid Overtime In Date Range Success',
  props<{
    employeePaidOverTimeInMinutes: number;
  }>()
);

export const calculateEmployeePaidOvertimeInDateRangeFailure = createAction(
  '[EmployeePaidOvertimeEffects] Calculate Employee Paid Overtime In Date Range Failure',
  props<{ error: string }>()
);

// Calculate Employee Paid Overtime in School Year
export const calculateEmployeePaidOvertimeInSchoolYear = createAction(
  '[EmployeePaidOvertimeEffects] Calculate Employee Paid Overtime In School Year',
  props<{
    employeePaidOvertime: EmployeePaidOvertime[];
    startDate: Date;
    endDate: Date;
  }>()
);

export const calculateEmployeePaidOvertimeInSchoolYearSuccess = createAction(
  '[EmployeePaidOvertimeEffects] Calculate Employee Paid Overtime In School Year Success',
  props<{
    employeePaidOverTimeInMinutes: number;
  }>()
);

export const calculateEmployeePaidOvertimeInSchoolYearFailure = createAction(
  '[EmployeePaidOvertimeEffects] Calculate Employee Paid Overtime In School Year Failure',
  props<{ error: string }>()
);

// Calculate Employee Paid Overtime Until Today
export const calculateEmployeePaidOvertimeUntilToday = createAction(
  '[EmployeePaidOvertimeEffects] Calculate Employee Paid Overtime Until Today',
  props<{
    employeePaidOvertime: EmployeePaidOvertime[];
    startDate: Date;
    endDate: Date;
  }>()
);

export const calculateEmployeePaidOvertimeUntilTodaySuccess = createAction(
  '[EmployeePaidOvertimeEffects] Calculate Employee Paid Overtime Until Today Success',
  props<{
    employeePaidOverTimeInMinutes: number;
  }>()
);

export const calculateEmployeePaidOvertimeUntilTodayFailure = createAction(
  '[EmployeePaidOvertimeEffects] Calculate Employee Paid Overtime Until Today Failure',
  props<{ error: string }>()
);
