import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minToDaysHoursMinutes'
})
export class MinToDaysHoursMinutesPipe implements PipeTransform {

  transform(min: number): string {

    const fixedMinutes = +min.toFixed(0);
    let hours = fixedMinutes / 60;
    const minutes = fixedMinutes % 60;

    let days = 0;

    if (hours > 24) {
      days = +(hours / 24).toFixed(0);
      hours = hours % 24;
    }

    console.log(hours)
    console.log(minutes)
    console.log(days)

    return 'hello'
  }

}
