import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrl: './modal-header.component.scss',
})
export class ModalHeaderComponent {
  modalController = inject(ModalController);

  @Input() title: string;
  @Input() subtitle: string;
  @Output() close = new EventEmitter<void>();

  onClose() {
    this.modalController.dismiss();
    this.close.emit();
  }
}
