import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectIsParent } from '../../../pages/auth/state/auth/auth.selectors';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss'],
})
export class ContentHeaderComponent {
  @Input() title: string;
  @Input() backButton: boolean;

  isParent$: Observable<boolean>;

  constructor(private store: Store) {
    this.isParent$ = this.store.select(selectIsParent);
  }
}
