import { createAction, props } from '@ngrx/store';
import { SchoolYear } from '../../../../shared/interfaces/school-year.interface';
import { CompanyVacation } from '../interfaces/company-vacation.interface';
import { Holiday } from '../../interfaces/holiday.interface';
import { addDataToEmployeeCompanyVacationsFailure } from '../../employee-company-vacations/state/employee-company-vacations.actions';

export const loadCompanyVacations = createAction(
  '[ CompanyVacationsResolver ] Load Company Vacations',
  props<{ schoolId: string; schoolYear: SchoolYear }>()
);

export const loadCompanyVacationsSuccess = createAction(
  '[ CompanyVacationsEffects ] Load Company Vacations Success',
  props<{ companyVacations: CompanyVacation[] }>()
);

export const loadCompanyVacationsError = createAction(
  '[ CompanyVacationsEffects ] Load Company Vacations Error',
  props<{ error: string }>()
);

// Add Data to Company Vacations
export const addDataToCompanyVacations = createAction(
  '[ CompanyVacationsEffects ] Add Data to Company Vacations',
  props<{
    companyVacations: CompanyVacation[];
    schoolYears: SchoolYear[];
    holidays: Holiday[];
    selectedSchoolYear: SchoolYear;
  }>()
);

export const addDataToCompanyVacationsSuccess = createAction(
  '[ CompanyVacationsEffects ] Add Data to Company Vacations',
  props<{ companyVacations: CompanyVacation[] }>()
);

export const addDataToCompanyVacationsFailure = createAction(
  '[ CompanyVacationsEffects ] Add Data to Company Vacations',
  props<{ error: string }>()
);
