import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedState } from '../shared.reducer-map';

export const selectLanguagesFeatureSelector =
  createFeatureSelector<SharedState>('shared');

export const selectLanguages = createSelector(
  selectLanguagesFeatureSelector,
  (state) => state.languages.languagesData
);

export const selectLanguagesLoaded = createSelector(
  selectLanguagesFeatureSelector,
  (state) => state.languages.languagesDataLoaded
);

export const selectLanguagesError = createSelector(
  selectLanguagesFeatureSelector,
  (state) => state.languages.languagesDataError
);
