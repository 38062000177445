import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { School } from '../../interfaces/school.interface';
import { selectSchool } from '../../state/school/school.selectors';

@Component({
  selector: 'app-content-header-logo',
  templateUrl: './content-header-logo.component.html',
  styleUrls: ['./content-header-logo.component.scss'],
})
export class ContentHeaderLogoComponent implements OnInit {
  school$: Observable<School>;
  constructor(private store: Store) {}

  ngOnInit() {
    this.school$ = this.store.select(selectSchool);
  }
}
