import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthMapState } from '../../auth-map.reducer';
import { AuthState } from './auth.reducer';

export const selectAuthFeatureSelector =
  createFeatureSelector<AuthMapState>('auth');

export const selectUserLoaded = createSelector(
  selectAuthFeatureSelector,
  (state) => state.auth.userLoaded
);

export const selectUserId = createSelector(
  selectAuthFeatureSelector,
  (state) => state.auth.user.id
);

export const selectAuthUserError = createSelector(
  selectAuthFeatureSelector,
  (state) => state.auth.userError
);

export const selectSchoolId = createSelector(
  selectAuthFeatureSelector,
  (state) => {
    if (state.auth.user?.schoolId) {
      return state.auth.user.schoolId;
    } else if (state.auth.user?.parentOfSchool) {
      return state.auth.user?.parentOfSchool;
    }
  }
);

export const selectIsAuthenticated = createSelector(
  selectAuthFeatureSelector,
  (state: AuthMapState) => (state.auth.user ? true : false)
);

export const selectUser = createSelector(
  selectAuthFeatureSelector,
  (state) => state.auth.user
);

export const selectUserFirstName = createSelector(
  selectAuthFeatureSelector,
  (state) => state.auth.user.firstName
);

export const selectIsParent = createSelector(
  selectAuthFeatureSelector,
  (state) => !!state.auth.user.parentOfSchool
);

export const selectIsEmployee = createSelector(
  selectAuthFeatureSelector,
  (state) => !!state.auth.user.employedBy
);
