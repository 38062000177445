import { createAction, props } from '@ngrx/store';
import { EmployeeAbsenceCategory } from '../interfaces/employee-absence-category.interface';

// Load Employee Absence Categories
export const loadEmployeeAbsenceCategories = createAction(
  '[ EmployeeAbsenceCategoriesResolver ] Load Employee Absence Categories',
  props<{ schoolId: string }>()
);

export const loadEmployeeAbsenceCategoriesSuccess = createAction(
  '[ EmployeeAbsenceCategoriesEffects ] Load Employee Absence Categories Success',
  props<{ employeeAbsenceCategories: EmployeeAbsenceCategory[] }>()
);

export const loadEmployeeAbsenceCategoriesFailure = createAction(
  '[ EmployeeAbsenceCategoriesEffects ] Load Employee Absence Categories Failed',
  props<{ error: string }>()
);
