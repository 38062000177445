import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ParentInformationCategoriesActions } from '../../../pages/parents/parent-informations/state/parent-information-categories/parent-information-categories-action-types';
import { ParentInformationCategory } from '../../../pages/parents/parent-informations/interfaces/parent-information-category.interface';
import { selectParentInformationCategories } from '../../../pages/parents/parent-informations/state/parent-information-categories/parent-information-categories.selectors';
import { Router } from '@angular/router';
import { selectIsParent } from '../../../pages/auth/state/auth/auth.selectors';

@Component({
  selector: 'app-parent-informations-categories-menu',
  templateUrl: './parent-informations-categories-menu.component.html',
  styleUrls: ['./parent-informations-categories-menu.component.scss'],
})
export class ParentInformationsCategoriesMenuComponent implements OnInit {
  parentInformationCategories$: Observable<ParentInformationCategory[]>;
  isParent$: Observable<boolean>;
  constructor(private store: Store, private router: Router) {}

  ngOnInit() {
    this.parentInformationCategories$ = this.store.select(
      selectParentInformationCategories
    );

    this.isParent$ = this.store.select(selectIsParent);
  }

  onSelectParentInformationCategory(parentInformationCategoryId: string) {
    this.store.dispatch(
      ParentInformationCategoriesActions.setParentInformationCategory({
        parentInformationCategoryId,
      })
    );

    this.router.navigate(['/parents/tabs/list']);
  }
}
