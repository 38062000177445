import { createAction, props } from '@ngrx/store';
import { EmployeeAbsence } from '../interfaces/employee-absence.interface';
import { SchoolYear } from '../../../../shared/interfaces/school-year.interface';
import { EmployeeContract } from '../../employee-contracts/interfaces/employee-contract.interface';
import { Holiday } from '../../interfaces/holiday.interface';
import { EmployeeAbsenceCategory } from '../../employee-absence-categories/interfaces/employee-absence-category.interface';

// Load Employee Absences
export const loadEmployeeAbsences = createAction(
  '[ EmployeeAbsencesResolver ] Load Employee Absences',
  props<{ employeeId: string; schoolYear: SchoolYear }>()
);

export const loadEmployeeAbsencesSuccess = createAction(
  '[ EmployeeAbsencesEffects ] Load Employee  Absences Success',
  props<{ employeeAbsences: EmployeeAbsence[] }>()
);

export const loadEmployeeAbsencesFailure = createAction(
  '[ EmployeeAbsencesEffects ] Load Employee  Absences Failed',
  props<{ error: string }>()
);

// Add Data to Employee Absences
export const addDataToEmployeeAbsences = createAction(
  '[ EmployeeAbsencesEffects ] Add Data to Employee Absences',
  props<{
    employeeAbsences: EmployeeAbsence[];
    employeeAbsenceCategories: EmployeeAbsenceCategory[];
    employeeContracts: EmployeeContract[];
    holidays: Holiday[];
  }>()
);

export const addDataToEmployeeAbsencesSuccess = createAction(
  '[ EmployeeAbsencesEffects ] Add Data to Employee  Absences Success',
  props<{ employeeAbsences: EmployeeAbsence[] }>()
);

export const addDataToEmployeeAbsencesFailure = createAction(
  '[ EmployeeAbsencesEffects ] Add Data to Employee  Absences Failed',
  props<{ error: string }>()
);

// Add Employee Absences
export const addEmployeeAbsence = createAction(
  '[ AddUpdateEmployeeAbsenceContainer ] Add Employee  Absence',
  props<{ employeeAbsence: EmployeeAbsence }>()
);

export const addEmployeeAbsenceSuccess = createAction(
  '[ EmployeeAbsencesEffects ] Add Employee  Absence Success',
  props<{ employeeAbsence: EmployeeAbsence }>()
);

export const addEmployeeAbsenceFailure = createAction(
  '[ EmployeeAbsencesEffects ] Add Employee  Absence Failed',
  props<{ error: string }>()
);

// Update Employee Absence
export const updateEmployeeAbsence = createAction(
  '[ AddUpdateEmployeeAbsenceContainer ] Update Employee Absence',
  props<{ employeeAbsence: EmployeeAbsence }>()
);

export const updateEmployeeAbsenceSuccess = createAction(
  '[ EmployeeAbsencesEffects ] Update Employee Absence  Success',
  props<{ employeeAbsence: EmployeeAbsence }>()
);

export const updateEmployeeAbsenceFailure = createAction(
  '[ EmployeeAbsencesEffects ] Update Employee  Absence Failed',
  props<{ error: string }>()
);

// Delete Employee  Absence
export const deleteEmployeeAbsence = createAction(
  '[ EmployeeAbsenceListContainer ] Delete Employee Absence',
  props<{ employeeAbsence: EmployeeAbsence }>()
);

export const deleteEmployeeAbsenceSuccess = createAction(
  '[ EmployeeAbsencesEffects ] Delete Employee  Absence Success',
  props<{ employeeAbsence: EmployeeAbsence }>()
);

export const deleteEmployeeAbsenceFailure = createAction(
  '[ EmployeeAbsencesEffects ] Delete Employee  Absence Failed',
  props<{ error: string }>()
);

// Select Employee  Absence
export const selectEmployeeAbsence = createAction(
  '[ EmployeeAbsencesListContainer ] Select Employee  Absence',
  props<{ employeeAbsence: EmployeeAbsence }>()
);

// Calculate Employee Full Day Absences in Date Range
export const calculateEmployeeFullDayAbsencesInDateRange = createAction(
  '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences in Date Range',
  props<{
    startDate: Date;
    endDate: Date;
    employeeFullDayAbsences: EmployeeAbsence[];
    employeeAbsenceCategories: EmployeeAbsenceCategory[];
    holidays: Holiday[];
    employeeContracts: EmployeeContract[];
  }>()
);

export const calculateEmployeeFullDayAbsencesInDateRangeSuccess = createAction(
  '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences in Date Range Success',
  props<{
    employeeFullDayAbsencesInDateRangeInMinutes: number;
    employeeFullDayAbsencesDaysInDateRange: number;
    employeeFullDayAbsencesByCategory: any[];
  }>()
);

export const calculateEmployeeFullDayAbsencesInDateRangeFailure = createAction(
  '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences in Date Range Failed',
  props<{ error: string }>()
);

// Calculate Employee Full Day Absences with Time Slots in Date Range
export const calculateEmployeeFullDayAbsencesWithTimeSlotsInDateRange =
  createAction(
    '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences with Time Slots in Date Range',
    props<{
      startDate: Date;
      endDate: Date;
      employeeFullDayAbsencesWithTimeSlots: EmployeeAbsence[];
      employeeAbsenceCategories: EmployeeAbsenceCategory[];
      employeeContracts: EmployeeContract[];
    }>()
  );

export const calculateEmployeeFullDayAbsencesWithTimeSlotsInDateRangeSuccess =
  createAction(
    '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences with Time Slots in Date Range Success',
    props<{
      employeeFullDayAbsencesWithTimeSlotsInDateRangeInMinutes: number;
      employeeFullDayAbsencesDaysWithTimeSlotsInDateRange: number;
      employeeFullDayAbsencesWithTimeSlotsByCategory: any[];
    }>()
  );

export const calculateEmployeeFullDayAbsencesWithTimeSlotsInDateRangeFailure =
  createAction(
    '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences with Time Slots in Date Range Failed',
    props<{ error: string }>()
  );

// Calculate Employee Intra Day Absences in Date Range
export const calculateEmployeeIntraDayAbsencesInDateRange = createAction(
  '[ EmployeeAbsencesEffects ] Calculate Employee Intra Day Absences in Date Range',
  props<{
    startDate: Date;
    endDate: Date;
    employeeIntraDayAbsences: EmployeeAbsence[];
    employeeAbsenceCategories: EmployeeAbsenceCategory[];
  }>()
);

export const calculateEmployeeIntraDayAbsencesInDateRangeSuccess = createAction(
  '[ EmployeeAbsencesEffects ] Calculate Employee Intra Day Absences in Date Range Success',
  props<{
    employeeIntraDayAbsencesInDateRangeInMinutes: number;
    employeeIntraDayAbsencesDaysInDateRange: number;
    employeeIntraDayAbsencesByCategory: any[];
  }>()
);

export const calculateEmployeeIntraDayAbsencesInDateRangeFailure = createAction(
  '[ EmployeeAbsencesEffects ] Calculate Employee Intra Day Absences in Date Range Failed',
  props<{ error: string }>()
);

// Calculate Employee Absences in School Year
// Calculate Employee Full Day Absences in School Year
export const calculateEmployeeFullDayAbsencesInSchoolYear = createAction(
  '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences in School Year',
  props<{
    startDate: Date;
    endDate: Date;
    employeeFullDayAbsences: EmployeeAbsence[];
    employeeAbsenceCategories: EmployeeAbsenceCategory[];
    holidays: Holiday[];
    employeeContracts: EmployeeContract[];
  }>()
);

export const calculateEmployeeFullDayAbsencesInSchoolYearSuccess = createAction(
  '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences in School Year Success',
  props<{
    employeeFullDayAbsencesInMinutesInSchoolYear: number;
    employeeFullDayAbsencesDaysInSchoolYear: number;
    employeeFullDayAbsencesByCategoryInSchoolYear: any[];
  }>()
);

export const calculateEmployeeFullDayAbsencesInSchoolYearFailure = createAction(
  '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences in School Year Failed',
  props<{ error: string }>()
);

// Calculate Employee Full Day Absences with Time Slots in School Year
export const calculateEmployeeFullDayAbsencesWithTimeSlotsInSchoolYear =
  createAction(
    '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences with Time Slots in School Year',
    props<{
      startDate: Date;
      endDate: Date;
      employeeFullDayAbsencesWithTimeSlots: EmployeeAbsence[];
      employeeAbsenceCategories: EmployeeAbsenceCategory[];
      employeeContracts: EmployeeContract[];
    }>()
  );

export const calculateEmployeeFullDayAbsencesWithTimeSlotsInSchoolYearSuccess =
  createAction(
    '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences with Time Slots in School Year Success',
    props<{
      employeeFullDayAbsencesWithTimeSlotsInMinutesInSchoolYear: number;
      employeeFullDayAbsencesDaysWithTimeSlotsInSchoolYear: number;
      employeeFullDayAbsencesWithTimeSlotsByCategoryInSchoolYear: any[];
    }>()
  );

export const calculateEmployeeFullDayAbsencesWithTimeSlotsInSchoolYearFailure =
  createAction(
    '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences with Time Slots in School Year Failed',
    props<{ error: string }>()
  );

// Calculate Employee Intra Day Absences in School Year
export const calculateEmployeeIntraDayAbsencesInSchoolYear = createAction(
  '[ EmployeeAbsencesEffects ] Calculate Employee Intra Day Absences in School Year',
  props<{
    startDate: Date;
    endDate: Date;
    employeeIntraDayAbsences: EmployeeAbsence[];
    employeeAbsenceCategories: EmployeeAbsenceCategory[];
  }>()
);

export const calculateEmployeeIntraDayAbsencesInSchoolYearSuccess =
  createAction(
    '[ EmployeeAbsencesEffects ] Calculate Employee Intra Day Absences in School Year Success',
    props<{
      employeeIntraDayAbsencesInMinutesInSchoolYear: number;
      employeeIntraDayAbsencesDaysInSchoolYear: number;
      employeeIntraDayAbsencesByCategoryInSchoolYear: any[];
    }>()
  );

export const calculateEmployeeIntraDayAbsencesInSchoolYearFailure =
  createAction(
    '[ EmployeeAbsencesEffects ] Calculate Employee Intra Day Absences in School Year Failed',
    props<{ error: string }>()
  );

// Calculate Employee Absences Until Today
// Calculate Employee Full Day Absences Until Today
export const calculateEmployeeFullDayAbsencesUntilToday = createAction(
  '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences Until Today',
  props<{
    startDate: Date;
    endDate: Date;
    employeeFullDayAbsences: EmployeeAbsence[];
    employeeAbsenceCategories: EmployeeAbsenceCategory[];
    holidays: Holiday[];
    employeeContracts: EmployeeContract[];
  }>()
);

export const calculateEmployeeFullDayAbsencesUntilTodaySuccess = createAction(
  '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences Until todaySuccess',
  props<{
    employeeFullDayAbsencesInMinutesUntilToday: number;
    employeeFullDayAbsencesDaysUntilToday: number;
    employeeFullDayAbsencesByCategoryUntilToday: any[];
  }>()
);

export const calculateEmployeeFullDayAbsencesUntilTodayFailure = createAction(
  '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences Until Today Failed',
  props<{ error: string }>()
);

// Calculate Employee Full Day Absences with Time Slots Until Today
export const calculateEmployeeFullDayAbsencesWithTimeSlotsUntilToday =
  createAction(
    '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences with Time Slots Until Today',
    props<{
      startDate: Date;
      endDate: Date;
      employeeFullDayAbsencesWithTimeSlots: EmployeeAbsence[];
      employeeAbsenceCategories: EmployeeAbsenceCategory[];
      employeeContracts: EmployeeContract[];
    }>()
  );

export const calculateEmployeeFullDayAbsencesWithTimeSlotsUntilTodaySuccess =
  createAction(
    '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences with Time Slots Until Today Success',
    props<{
      employeeFullDayAbsencesWithTimeSlotsInMinutesUntilToday: number;
      employeeFullDayAbsencesDaysWithTimeSlotsUntilToday: number;
      employeeFullDayAbsencesWithTimeSlotsByCategoryUntilToday: any[];
    }>()
  );

export const calculateEmployeeFullDayAbsencesWithTimeSlotsUntilTodayFailure =
  createAction(
    '[ EmployeeAbsencesEffects ] Calculate Employee Full Day Absences with Time Slots Until Today Failed',
    props<{ error: string }>()
  );

// Calculate Employee Intra Day Absences Until Today
export const calculateEmployeeIntraDayAbsencesUntilToday = createAction(
  '[ EmployeeAbsencesEffects ] Calculate Employee Intra Day Absences Until Today',
  props<{
    startDate: Date;
    endDate: Date;
    employeeIntraDayAbsences: EmployeeAbsence[];
    employeeAbsenceCategories: EmployeeAbsenceCategory[];
  }>()
);

export const calculateEmployeeIntraDayAbsencesUntilTodaySuccess = createAction(
  '[ EmployeeAbsencesEffects ] Calculate Employee Intra Day Absences Until TodaySuccess',
  props<{
    employeeIntraDayAbsencesInMinutesUntilToday: number;
    employeeIntraDayAbsencesDaysUntilToday: number;
    employeeIntraDayAbsencesByCategoryUntilToday: any[];
  }>()
);

export const calculateEmployeeIntraDayAbsencesUntilTodayFailure = createAction(
  '[ EmployeeAbsencesEffects ] Calculate Employee Intra Day Absences Until Today Failed',
  props<{ error: string }>()
);
