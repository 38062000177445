import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HolidaysActions } from './holidays-action-types';
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  tap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthActions } from 'src/app/pages/auth/state/auth/auth-action-types';
import { SchoolYearsActions } from 'src/app/shared/state/school-years/school-years-action-types';
import { selectSelectedSchoolYear } from '../../state/school-years/school-years.selectors';
import { selectSchoolId } from '../../../pages/auth/state/auth/auth.selectors';
import { filter } from 'rxjs/operators';
import { HolidaysService } from '../services/holidays.service';
import { Holiday } from 'src/app/pages/employee-work-time/interfaces/holiday.interface';

@Injectable({ providedIn: 'root' })
export class HolidayEffects {
  listenForLoadHolidays$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SchoolYearsActions.setCurrentSchoolYearAsSelectedSchoolYearSuccess,
        SchoolYearsActions.setSelectedSchoolYear
      ),
      switchMap(() =>
        this.store.select(selectSelectedSchoolYear).pipe(
          withLatestFrom(this.store.select(selectSchoolId)),
          filter(([schoolYear, schoolId]) => !!schoolYear && !!schoolId),
          map(([schoolYear, schoolId]) =>
            HolidaysActions.loadHolidays({ schoolYear, schoolId })
          )
        )
      )
    )
  );

  loadHolidays$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HolidaysActions.loadHolidays),

      switchMap((action) =>
        this.holidaysService
          .loadHolidays(action.schoolYear, action.schoolId)
          .pipe(
            map((holidays: Holiday[]) =>
              HolidaysActions.loadHolidaysSuccess({ holidays })
            ),
            catchError((error) =>
              of(HolidaysActions.loadHolidaysFailure({ error }))
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private holidaysService: HolidaysService,
    private store: Store
  ) {}
}
