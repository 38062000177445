import { createAction, props } from '@ngrx/store';
import { User } from '../../interfaces/user.interface';

// Login
export const login = createAction(
  '[] User Login',
  props<{ credentials: { email: string; password: string } }>()
);

export const loginSuccess = createAction(
  '[] User Login Success',
  props<{ user: User }>()
);

export const loginFailure = createAction(
  '[] User Login Fail',
  props<{ error: string }>()
);

export const logout = createAction('[] User Logout');

// Save E-Mail in Local Storage
export const saveEmailInLocalStorage = createAction(
  '[LoginContainer] Save E-Mail in Local Storage',
  props<{ email: string }>()
);

// Set User Roles
export const setUserRoles = createAction(
  '[ AuthEffects ] Set User Roles',
  props<{ user: User }>()
);
