import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedState } from '../shared.reducer-map';

export const selectSchoolFeatureSelector =
  createFeatureSelector<SharedState>('shared');

export const selectSchoolIdFromUrl = createSelector(
  selectSchoolFeatureSelector,
  (state) => state.school.schoolData?.id
);

export const selectSchoolNameFromUrl = createSelector(
  selectSchoolFeatureSelector,
  (state) => state.school.schoolData?.name
);

export const selectSchool = createSelector(
  selectSchoolFeatureSelector,
  (state) => state.school.schoolData
);
