import { createAction, props } from '@ngrx/store';
import { EmployeeContract } from '../interfaces/employee-contract.interface';

// Load Employee Contracts
export const loadEmployeeContracts = createAction(
  '[ EmployeeContractsResolver ] Load Employee Contracts',
  props<{ employeeId: string }>()
);

export const loadEmployeeContractsSuccess = createAction(
  '[ EmployeeContractsEffects ] Load Employee Contracts Success',
  props<{ employeeContracts: EmployeeContract[] }>()
);

export const loadEmployeeContractsFailure = createAction(
  '[ EmployeeContractsEffects ] Load Employee Contracts Failed',
  props<{ error: string }>()
);

// Select and Deselect Employee Contract
export const selectEmployeeContract = createAction(
  '[ EmployeeContractsListContainer ] Select Employee Contract',
  props<{ selectedEmployeeContract: EmployeeContract }>()
);
