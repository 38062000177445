import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { selectSchoolId } from 'src/app/pages/auth/state/auth/auth.selectors';
import { EmployeeAbsenceCategoriesActions } from '../state/employee-absence-categories-action-types';
import { selectEmployeeAbsenceCategoriesLoaded } from '../state/employee-absence-categories.selectors';

@Injectable({ providedIn: 'root' })
export class EmployeeAbsenceCategoriesResolver {
  constructor(private store: Store) {}
  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(selectEmployeeAbsenceCategoriesLoaded).pipe(
      switchMap((loaded) => {
        if (!loaded) {
          return this.store.select(selectSchoolId).pipe(
            tap((schoolId) => {
              this.store.dispatch(
                EmployeeAbsenceCategoriesActions.loadEmployeeAbsenceCategories({
                  schoolId,
                })
              );
            }),
            map(() => false)
          );
        } else {
          return of(true);
        }
      }),
      filter((loaded) => !!loaded),
      first()
    );
  }
}
