import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { DateTime } from 'luxon';
import { selectSelectedSchoolYear } from '../../state/school-years/school-years.selectors';
import { Subscription } from 'rxjs';
import { SchoolYear } from '../../interfaces/school-year.interface';

@Component({
  selector: 'app-select-time-range',
  templateUrl: './select-time-range.component.html',
  styleUrls: ['./select-time-range.component.scss'],
})
export class SelectTimeRangeComponent implements OnInit, OnDestroy {
  startDate = new FormControl();
  endDate = new FormControl();
  form: FormGroup;

  startDatePicker = false;
  endDatePicker = false;

  displayedStartDate = DateTime.now();
  displayedEndDate = DateTime.now().endOf('day').toISO();

  schoolYear: SchoolYear;
  schoolYearSubs = new Subscription();

  constructor(private fb: FormBuilder, private store: Store) {}

  ngOnInit() {
    this.schoolYearSubs = this.store
      .select(selectSelectedSchoolYear)
      .subscribe((schoolYear) => {
        this.startDate.setValue(
          DateTime.fromISO(schoolYear.startDate).toJSDate()
        );
        this.endDate.setValue(DateTime.fromISO(schoolYear.endDate).toJSDate());
        this.schoolYear = schoolYear;
      });

    this.form = this.fb.group({
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }

  setStartDate(startDate) {
    this.startDate.setValue(DateTime.fromISO(startDate).toJSDate());
    this.displayedStartDate = DateTime.fromISO(startDate);
    this.startDatePicker = false;
  }

  setEndDate(endDate) {
    this.startDate.setValue(DateTime.fromISO(endDate).toJSDate());
    this.displayedEndDate = DateTime.fromISO(endDate);
    this.endDatePicker = false;
  }

  toggleStartDatePicker() {
    this.startDatePicker = !this.startDatePicker;
  }

  toggleEndDatePicker() {
    this.endDatePicker = !this.endDatePicker;
  }

  submit() {
    console.log(this.form.value);
  }

  ngOnDestroy() {
    this.schoolYearSubs.unsubscribe();
  }
}
