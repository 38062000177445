import { createReducer, on } from '@ngrx/store';
import { SchoolActions } from './school-action-types';
import { School } from '../../interfaces/school.interface';
export interface SchoolState {
  schoolData: School;
  schoolDataLoaded: boolean;
  schoolDataError: string;
}

const initialState: SchoolState = {
  schoolData: null,
  schoolDataLoaded: false,
  schoolDataError: '',
};

export const schoolReducer = createReducer<SchoolState>(
  initialState,

  on(
    SchoolActions.findSchoolSuccess,
    (state, action): SchoolState => ({
      ...state,
      schoolData: action.school,
      schoolDataLoaded: true,
      schoolDataError: '',
    })
  ),
  on(
    SchoolActions.findSchoolFailure,
    (state, action): SchoolState => ({
      ...state,
      schoolData: null,
      schoolDataLoaded: false,
      schoolDataError: action.error,
    })
  )
);
