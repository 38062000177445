import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmployeeWorkTimeReducerMapState } from '../../state/work-time.reducer-map';

export const selectEmployeeCompanyVacationsFeatureSelector =
  createFeatureSelector<EmployeeWorkTimeReducerMapState>(
    'employee-work-time-reducer-map'
  );

// Select Employee Company Vacations with Data
export const selectFetchedEmployeeCompanyVacations = createSelector(
  selectEmployeeCompanyVacationsFeatureSelector,
  (state) => state.employeeCompanyVacations.fetchedEmployeeCompanyVacationsData
);

export const selectFetchedEmployeeCompanyVacationsLoaded = createSelector(
  selectEmployeeCompanyVacationsFeatureSelector,
  (state) =>
    state.employeeCompanyVacations.fetchedEmployeeCompanyVacationsDataLoaded
);

export const selectFetchedEmployeeCompanyVacationsFailed = createSelector(
  selectEmployeeCompanyVacationsFeatureSelector,
  (state) =>
    state.employeeCompanyVacations.fetchedEmployeeCompanyVacationsDataError
);

// Select Employee Company Vacations with Data
export const selectEmployeeCompanyVacations = createSelector(
  selectEmployeeCompanyVacationsFeatureSelector,
  (state) => state.employeeCompanyVacations.employeeCompanyVacationsData
);

export const selectEmployeeCompanyVacationsLoaded = createSelector(
  selectEmployeeCompanyVacationsFeatureSelector,
  (state) => state.employeeCompanyVacations.employeeCompanyVacationsData
);

export const selectEmployeeCompanyVacationsFailed = createSelector(
  selectEmployeeCompanyVacationsFeatureSelector,
  (state) => state.employeeCompanyVacations.employeeCompanyVacationsData
);

// Select Employee Company Vacations in Date Range
export const selectEmployeeCompanyVacationsInMinutes = createSelector(
  selectEmployeeCompanyVacationsFeatureSelector,
  (state) => state.employeeCompanyVacations.employeeCompanyVacationsInMinutes
);

export const selectEmployeeCompanyVacationsInDays = createSelector(
  selectEmployeeCompanyVacationsFeatureSelector,
  (state) => state.employeeCompanyVacations.employeeCompanyVacationsDays
);

// Select Employee Company Vacations Until Today
export const selectEmployeeCompanyVacationsInMinutesUntilToday = createSelector(
  selectEmployeeCompanyVacationsFeatureSelector,
  (state) =>
    state.employeeCompanyVacations.employeeCompanyVacationsInMinutesUntilToday
);

export const selectEmployeeCompanyVacationsInDaysUntilToday = createSelector(
  selectEmployeeCompanyVacationsFeatureSelector,
  (state) =>
    state.employeeCompanyVacations.employeeCompanyVacationsDaysUntilToday
);

// Select Employee Company Vacations In School Year
export const selectEmployeeCompanyVacationsInMinutesInSchoolYear =
  createSelector(
    selectEmployeeCompanyVacationsFeatureSelector,
    (state) =>
      state.employeeCompanyVacations
        .employeeCompanyVacationsInMinutesInSchoolYear
  );

export const selectEmployeeCompanyVacationsInDaysInSchoolYear = createSelector(
  selectEmployeeCompanyVacationsFeatureSelector,
  (state) =>
    state.employeeCompanyVacations.employeeCompanyVacationsDaysInSchoolYear
);
