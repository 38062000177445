// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(68, 68, 68, 0.1);
  z-index: 999;
}

ion-spinner {
  width: 40px;
  height: 40px;
}

.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  position: relative;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #3dc2ff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce1 {
  background-color: #3dc2ff;
}

.double-bounce2 {
  animation-delay: -1s;
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/loading/loading.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,YAAA;EACA,uCAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;;EAEE,WAAA;EACA,YAAA;EACA,kBAAA;EAEA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EAEA,4CAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EAEE,oBAAA;AAAF;AAaA;EACE;IAEE,mBAAA;IACA,2BAAA;EAHF;EAKA;IACE,mBAAA;IACA,2BAAA;EAHF;AACF","sourcesContent":[".spinner-container {\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100vh;\n  width: 100vw;\n  background-color: rgba(#444, 0.1);\n  z-index: 999;\n}\n\nion-spinner {\n  width: 40px;\n  height: 40px;\n}\n\n.spinner {\n  width: 40px;\n  height: 40px;\n  margin: 100px auto;\n  position: relative;\n}\n\n.double-bounce1,\n.double-bounce2 {\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n  // background-color: #ffc409;\n  background-color: #3dc2ff;\n  opacity: 0.6;\n  position: absolute;\n  top: 0;\n  left: 0;\n  -webkit-animation: sk-bounce 2s infinite ease-in-out;\n  animation: sk-bounce 2s infinite ease-in-out;\n}\n\n.double-bounce1 {\n  background-color: #3dc2ff;\n}\n\n.double-bounce2 {\n  -webkit-animation-delay: -1s;\n  animation-delay: -1s;\n}\n\n@-webkit-keyframes sk-bounce {\n  0%,\n  100% {\n    -webkit-transform: scale(0);\n  }\n  50% {\n    -webkit-transform: scale(1);\n  }\n}\n\n@keyframes sk-bounce {\n  0%,\n  100% {\n    transform: scale(0);\n    -webkit-transform: scale(0);\n  }\n  50% {\n    transform: scale(1);\n    -webkit-transform: scale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
