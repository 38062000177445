import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmployeeWorkTimeReducerMapState } from '../../state/work-time.reducer-map';

export const selectEmployeePaidOvertimeFeatureSelector =
  createFeatureSelector<EmployeeWorkTimeReducerMapState>(
    'employee-work-time-reducer-map'
  );

export const selectEmployeePaidOvertime = createSelector(
  selectEmployeePaidOvertimeFeatureSelector,
  (state) => state.employeePaidOvertime.employeePaidOvertimeData
);

export const selectEmployeePaidOvertimeLoaded = createSelector(
  selectEmployeePaidOvertimeFeatureSelector,
  (state) => state.employeePaidOvertime.employeePaidOvertimeDataLoaded
);

export const selectEmployeePaidOvertimeError = createSelector(
  selectEmployeePaidOvertimeFeatureSelector,
  (state) => state.employeePaidOvertime.employeePaidOvertimeDataError
);

export const selectSelectedEmployeePaidOvertime = createSelector(
  selectEmployeePaidOvertimeFeatureSelector,
  (state) => state.employeePaidOvertime.selectedEmployeePaidOvertime
);

export const selectEmployeePaidOvertimeInMinutes = createSelector(
  selectEmployeePaidOvertimeFeatureSelector,
  (state) => state.employeePaidOvertime.employeePaidOvertimeInMinutes
);

export const selectEmployeePaidOvertimeInMinutesInSchoolYear = createSelector(
  selectEmployeePaidOvertimeFeatureSelector,
  (state) =>
    state.employeePaidOvertime.employeePaidOvertimeInMinutesInSchoolYear
);
export const selectEmployeePaidOvertimeInMinutesUntilToday = createSelector(
  selectEmployeePaidOvertimeFeatureSelector,
  (state) => state.employeePaidOvertime.employeePaidOvertimeInMinutesUntilToday
);
