import { AuthState, authReducer } from './state/auth/auth.reducer';
import {
  UsersDefaultLanguageState,
  usersDefaultLanguageReducer,
} from './state/users-default-language/users-default-language.reducer';
import { ActionReducerMap } from '@ngrx/store';

export interface AuthMapState {
  auth: AuthState;
  usersDefaultLanguage: UsersDefaultLanguageState;
}

export const authMapReducer: ActionReducerMap<AuthMapState> = {
  auth: authReducer,
  usersDefaultLanguage: usersDefaultLanguageReducer,
};
