import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthActions } from '../../../state/auth/auth-action-types';
import { AuthService } from '../../../services/auth.service';
import {
  selectSchoolIdFromUrl,
  selectSchoolNameFromUrl,
} from '../../../../../shared/state/school/school.selectors';
import { Observable, of, pipe, Subscription } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { UsersDefaultLanguageActions } from 'src/app/pages/auth/state/users-default-language/users-default-language-action-types';
import { UsersDefaultLanguageService } from '../../../services/users-default-language.service';
import { Language } from '../../../../parents/parent-informations/interfaces/language.interface';
import { selectLanguages } from '../../../../../shared/state/languages/languages.selectors';
import { selectSchool } from '../../../../../shared/state/school/school.selectors';
import { School } from '../../../../../shared/interfaces/school.interface';
import { selectAuthUserError } from '../../../state/auth/auth.selectors';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss'],
})
export class LoginContainerComponent implements OnInit, OnDestroy {
  email = '';
  school$: Observable<School>;
  errorSubs = new Subscription();

  authError$ = this.store.select(selectAuthUserError);

  constructor(
    private store: Store,
    private alertController: AlertController,
    private router: Router,
    private authService: AuthService,
    private usersDefaultLanguageService: UsersDefaultLanguageService
  ) {}

  ngOnInit() {
    this.errorSubs = this.store
      .select(selectAuthUserError)
      .pipe(
        filter((error) => !!error),
        map((error) => {
          console.log(error);
          if (error === 'auth/wrong-password') {
            this.presentAlert(
              'Login Error',
              'Wrong Email or Password',
              'Please retry to login with correct credentials!'
            );
          } else if (error === 'auth/too-many-requests') {
            this.presentAlert(
              'Login Error',
              'To many requests',
              'Please slow down...there were to many login requests.'
            );
          }
        }),
        tap(() => this.router.navigate(['/']))
      )
      .subscribe();
    this.school$ = this.store
      .select(selectSchool)
      .pipe(filter((school) => !!school));

    const localStorageEmail = localStorage.getItem('email');

    if (localStorageEmail) {
      this.email = localStorageEmail;
    }
  }

  onlogin(credentials: { email: string; password: string }) {
    this.store.dispatch(AuthActions.login({ credentials }));
    this.store.dispatch(
      AuthActions.saveEmailInLocalStorage({ email: credentials.email })
    );
  }

  async presentAlert(header: string, subHeader: string, message: string) {
    const alert = await this.alertController.create({
      header,
      subHeader,
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  ngOnDestroy(): void {
    this.errorSubs.unsubscribe();
  }
}
