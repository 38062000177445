import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, first, filter } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { SchoolYearsService } from '../../services/school-years/school-years.service';
import { SchoolYearsActions } from './school-years-action-types';
import { Store } from '@ngrx/store';
import { selectSchoolYears } from './school-years.selectors';
import { AuthActions } from 'src/app/pages/auth/state/auth/auth-action-types';
import { selectSchoolId } from '../../../pages/auth/state/auth/auth.selectors';
import { School } from '../../interfaces/school.interface';

@Injectable({ providedIn: 'root' })
export class LastSchoolYearsEffects {
  listenForLoadLastSchoolYears$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      switchMap(() =>
        this.store.select(selectSchoolId).pipe(
          filter((schoolId) => !!schoolId),
          map((schoolId) => SchoolYearsActions.loadSchoolYears({ schoolId }))
        )
      )
    )
  );

  loadLastSchoolYears$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchoolYearsActions.loadSchoolYears),
      switchMap((action) =>
        this.schoolYearsService.loadSchoolYears(action.schoolId).pipe(
          map((schoolYears) =>
            SchoolYearsActions.loadSchoolYearsSuccess({
              schoolYears,
            })
          ),
          catchError((error) =>
            of(SchoolYearsActions.loadSchoolYearsFailure({ error }))
          )
        )
      )
    )
  );

  listenToSchoolYearsLoadedToSetCurrentSchoolYearAsSelectedSchoolYear$ =
    createEffect(() =>
      this.actions$.pipe(
        ofType(SchoolYearsActions.loadSchoolYearsSuccess),
        switchMap(() =>
          this.store.select(selectSchoolYears).pipe(
            filter((schoolYears) => !!schoolYears),
            map((schoolYears) =>
              SchoolYearsActions.setCurrentSchoolYearAsSelectedSchoolYear({
                schoolYears,
              })
            )
          )
        ),
        catchError((error) => throwError(() => error))
      )
    );

  findCurrentSchoolYearAndSetAsSelectedSchoolYear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchoolYearsActions.setCurrentSchoolYearAsSelectedSchoolYear),
      switchMap((action) =>
        this.schoolYearsService
          .findCurrentSchoolYearAndSetAsSelectedSchoolYear(action.schoolYears)
          .pipe(
            map((currentSchoolYear) =>
              SchoolYearsActions.setCurrentSchoolYearAsSelectedSchoolYearSuccess(
                { currentSchoolYear }
              )
            ),
            catchError((error) =>
              of(
                SchoolYearsActions.setCurrentSchoolYearAsSelectedSchoolYearFailure(
                  { error }
                )
              )
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private schoolYearsService: SchoolYearsService,
    private store: Store
  ) {}
}
