import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { Language } from 'src/app/pages/parents/parent-informations/interfaces/language.interface';
import { selectLanguages } from '../../state/languages/languages.selectors';
import { selectUsersDefaultLanguage } from '../../../pages/auth/state/users-default-language/users-default-language.selectors';
import { map, first, filter, take, tap } from 'rxjs/operators';
import { UsersDefaultLanguageActions } from 'src/app/pages/auth/state/users-default-language/users-default-language-action-types';
import { selectIsParent } from '../../../pages/auth/state/auth/auth.selectors';

@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss'],
})
export class LanguageSelectionComponent implements OnInit {
  usersDefaultLanguage$: Observable<Language>;
  languages$: Observable<Language[]>;
  isParent$: Observable<boolean>;

  defaultLanguage: Language;

  constructor(private store: Store) {}

  ngOnInit() {
    this.usersDefaultLanguage$ = this.store.select(selectUsersDefaultLanguage);
    this.languages$ = this.store.select(selectLanguages);
    this.setInitialDefaultLanguage();
    this.isParent$ = this.store.select(selectIsParent);
  }

  setInitialDefaultLanguage() {
    combineLatest([this.languages$, this.usersDefaultLanguage$])
      .pipe(
        filter(
          ([languages, usersDefaultLanguage]) =>
            !!languages && !!usersDefaultLanguage
        ),
        map(([languages, usersDefaultLanguage]) =>
          languages.find((language) => language.id === usersDefaultLanguage.id)
        ),
        first()
      )
      .subscribe((usersDefaultLanguage) => {
        this.defaultLanguage = usersDefaultLanguage;
      });
  }

  onSetDefaultLanguage(event: any) {
    const languageId = event.detail.value;
    this.store
      .select(selectLanguages)
      .pipe(
        tap((languages) => {
          const language = languages.find(
            (language) => language.id === languageId
          );
          this.store.dispatch(
            UsersDefaultLanguageActions.setUsersDefaultLanguageInLocalStorage({
              language,
            })
          );
        }),
        first()
      )
      .subscribe();
  }
}
