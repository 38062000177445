import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  collection,
  Firestore,
  query,
  where,
  orderBy,
  limit,
  CollectionReference,
  collectionData,
  Timestamp,
} from '@angular/fire/firestore';

import { SchoolYear } from '../../interfaces/school-year.interface';
import { Observable, throwError, EMPTY, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SchoolYearsService {
  schoolId: string;

  constructor(private store: Store, private firestore: Firestore) {}

  loadSchoolYears(schoolId: string): Observable<SchoolYear[]> {
    const schoolYearsRef = collection(
      this.firestore,
      `schools/${schoolId}/schoolYears`
    ) as CollectionReference<SchoolYear>;

    return collectionData<SchoolYear>(
      query(
        schoolYearsRef,
        where('isActive', '==', true),
        where('isVisibleForEmployees', '==', true),
        orderBy('endDate', 'desc'),
        limit(3)
      ),
      { idField: 'id' }
    ).pipe(
      map((schoolYears: SchoolYear[]) =>
        schoolYears.map((schoolYear) => ({
          ...schoolYear,
          startDate: (schoolYear.startDate as Timestamp).toDate(),
          endDate: (schoolYear.endDate as Timestamp).toDate(),
        }))
      ),
      catchError((error) => {
        console.log(error);
        throwError(() => error);
        return EMPTY;
      })
    );
  }

  findCurrentSchoolYearAndSetAsSelectedSchoolYear(
    schoolYears: SchoolYear[]
  ): Observable<SchoolYear> {
    const today = new Date();

    const currentSchoolYear = schoolYears.find(
      (schoolYear) =>
        schoolYear.startDate <= today && schoolYear.endDate >= today
    );

    return of(currentSchoolYear);
  }
}
