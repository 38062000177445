import { createAction, props } from '@ngrx/store';
import { School } from '../../interfaces/school.interface';

// Find School
export const findSchool = createAction(
  '[AppComponent] Find School',
  props<{ schoolUrl: string }>()
);

export const findSchoolSuccess = createAction(
  '[AppComponent] Find School Success',
  props<{ school: School }>()
);

export const findSchoolFailure = createAction(
  '[AppComponent] Find School Failed',
  props<{ error: string }>()
);
