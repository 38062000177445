import { Injectable } from '@angular/core';
import {
  CollectionReference,
  Firestore,
  collection,
  collectionData,
  orderBy,
  query,
  where,
} from '@angular/fire/firestore';
import { Timestamp } from 'firebase/firestore';
import { EMPTY, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Holiday } from '../../../pages/employee-work-time/interfaces/holiday.interface';
import { SchoolYear } from '../../interfaces/school-year.interface';

@Injectable({ providedIn: 'root' })
export class HolidaysService {
  constructor(private firestore: Firestore) {}

  loadHolidays(schoolYear: SchoolYear, schoolId: string) {
    const holidaysRef = collection(
      this.firestore,
      `schools/${schoolId}/holidays`
    ) as CollectionReference<Holiday>;

    return collectionData(
      query(
        holidaysRef,
        where('date', '>=', schoolYear.startDate),
        where('date', '<=', schoolYear.endDate),
        orderBy('date', 'asc')
      ),
      { idField: 'id' }
    ).pipe(
      map((holidays) =>
        holidays.map((holiday) => ({
          ...holiday,
          date: (holiday.date as Timestamp).toDate(),
        }))
      ),
      catchError((error) => {
        console.log(error);
        throwError(() => error);
        return EMPTY;
      })
    );
  }
}
