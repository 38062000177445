import { Injectable, inject } from '@angular/core';
import {
  Firestore,
  collection,
  CollectionReference,
  collectionData,
} from '@angular/fire/firestore';
import { School } from '../../interfaces/school.interface';
import { query } from '@firebase/firestore';
import { where } from '@angular/fire/firestore';
import { catchError, map, tap } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SchoolService {
  firestore = inject(Firestore);
  constructor() {}

  loadSchool(schoolUrl: string): Observable<School> {
    const schoolsRef = collection(
      this.firestore,
      `schools`
    ) as CollectionReference<School>;

    return collectionData(
      query(schoolsRef, where('schoolUrl', '==', schoolUrl)),
      { idField: 'id' }
    ).pipe(
      map((schools: School[]) => schools[0]),
      catchError((error) => throwError(() => error))
    );
  }
}
