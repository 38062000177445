import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState } from './loading.reducer';
import { SharedState } from '../shared.reducer-map';

export const selectSharedFeatureSelector =
  createFeatureSelector<SharedState>('shared');

export const selectLoading = createSelector(
  selectSharedFeatureSelector,
  (state) => state.loading.loading
);
