import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  switchMap,
  withLatestFrom,
  tap,
  map,
  filter,
  first,
} from 'rxjs/operators';

import { selectSelectedSchoolYear } from '../../../../shared/state/school-years/school-years.selectors';
import { EmployeeVacationsActions } from '../state/employee-vacations-action-types';
import {
  selectEmployeeVacationsLoaded,
  selectFetchedEmployeeVacations,
  selectFetchedEmployeeVacationsLoaded,
} from '../state/employee-vacations.selectors';
import { selectUser } from 'src/app/pages/auth/state/auth/auth.selectors';

@Injectable({ providedIn: 'root' })
export class EmployeeVacationsResolver implements Resolve<boolean> {
  constructor(private store: Store) {}
  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(selectFetchedEmployeeVacationsLoaded).pipe(
      switchMap((loaded) => {
        if (!loaded) {
          return this.store.select(selectUser).pipe(
            withLatestFrom(this.store.select(selectSelectedSchoolYear)),
            map(([user, schoolYear]) => {
              return EmployeeVacationsActions.loadEmployeeVacations({
                employeeId: user.id,
                schoolYear,
              });
            }),
            map(() => false)
          );
        } else {
          return of(true);
        }
      }),
      filter((loaded) => !!loaded),
      first()
    );
  }
}
