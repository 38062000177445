import { createAction, props } from '@ngrx/store';
import { SchoolYear } from '../../interfaces/school-year.interface';

// Load School Year
export const loadSchoolYears = createAction(
  '[Shared Action] Load School Years',
  props<{ schoolId: string }>()
);

export const loadSchoolYearsSuccess = createAction(
  '[Shared Action] Load School Years Success',
  props<{ schoolYears: SchoolYear[] }>()
);

export const loadSchoolYearsFailure = createAction(
  '[Shared Action] Load School Years Fail',
  props<{ error: string }>()
);

// Set Current School Year As Selected School Year
export const setCurrentSchoolYearAsSelectedSchoolYear = createAction(
  '[ Select Selected ] Set Current School Year As Selected School Year',
  props<{ schoolYears: SchoolYear[] }>()
);

export const setCurrentSchoolYearAsSelectedSchoolYearSuccess = createAction(
  '[ Select Selected ] Set Current School Year As Selected School Year Success',
  props<{ currentSchoolYear: SchoolYear }>()
);

export const setCurrentSchoolYearAsSelectedSchoolYearFailure = createAction(
  '[ Select Selected ] Set Current School Year As Selected School Year Failed',
  props<{ error: string }>()
);

// Set Selected School Year
export const setSelectedSchoolYear = createAction(
  '[ Select Selected ] Set Selected School Year',
  props<{ selectedSchoolYear: SchoolYear }>()
);
