import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { time } from 'console';
import { selectUser } from '../../../pages/auth/state/auth/auth.selectors';
import { Observable, first } from 'rxjs';
import { User } from '../../../pages/auth/interfaces/user.interface';

@Component({
  selector: 'app-greeting',
  templateUrl: './greeting.component.html',
  styleUrl: './greeting.component.scss',
})
export class GreetingComponent {
  user: User;

  greeting = 'Guten Tag';
  constructor(private store: Store) {
    this.store
      .select(selectUser)
      .pipe(first())
      .subscribe((user) => (this.user = user));
    this.setGreeting();
  }

  setGreeting() {
    const time = new Date().getHours();
    if (time > 6 && time < 12) {
      this.greeting = 'Guten Morgen';
    } else if (time > 12 && time < 18) {
      this.greeting = 'Guten Nachmittag';
    } else if (time > 18) {
      this.greeting = 'Guten Abend';
    }
  }
}
