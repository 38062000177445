import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmployeeWorkTimeReducerMapState } from '../../state/work-time.reducer-map';
export const selectEmployeeAbsenceCategoriesFeatureSelector =
  createFeatureSelector<EmployeeWorkTimeReducerMapState>(
    'employee-work-time-reducer-map'
  );

export const selectEmployeeAbsenceCategories = createSelector(
  selectEmployeeAbsenceCategoriesFeatureSelector,
  (state) => state.employeeAbsenceCategories.employeeAbsenceCategoriesData
);

export const selectEmployeeAbsenceCategoriesLoaded = createSelector(
  selectEmployeeAbsenceCategoriesFeatureSelector,
  (state) => state.employeeAbsenceCategories.employeeAbsenceCategoriesDataLoaded
);

export const selectEmployeeAbsenceCategoriesError = createSelector(
  selectEmployeeAbsenceCategoriesFeatureSelector,
  (state) => state.employeeAbsenceCategories.employeeAbsenceCategoriesDataError
);
