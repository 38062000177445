import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectSchoolYearsLoaded } from '../../state/school-years/school-years.selectors';
import { switchMap, tap, map, filter, first } from 'rxjs/operators';
import { selectSchoolId } from '../../../pages/auth/state/auth/auth.selectors';
import { SchoolYearsActions } from 'src/app/shared/state/school-years/school-years-action-types';

@Injectable({ providedIn: 'root' })
export class SchoolYearsResolver {
  constructor(private store: Store) {}
  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(selectSchoolYearsLoaded).pipe(
      switchMap((loaded) => {
        if (!loaded) {
          return this.store.select(selectSchoolId).pipe(
            filter((schoolId) => !!schoolId),
            tap((schoolId) =>
              this.store.dispatch(
                SchoolYearsActions.loadSchoolYears({ schoolId })
              )
            ),
            map(() => false)
          );
        } else {
          return of(true);
        }
      }),
      filter((loaded) => !!loaded),
      first()
    );
  }
}
