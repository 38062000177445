import { createAction, props } from '@ngrx/store';
import { TransferredBalance } from '../interfaces/transferred-balance.interface';
import { SchoolYear } from 'src/app/shared/interfaces/school-year.interface';

// Load TransferredBalances
export const loadTransferredBalances = createAction(
  '[TransferredBalancesResolver] Load TransferredBalances',
  props<{ employeeId: string }>()
);

export const loadTransferredBalancesSuccess = createAction(
  '[TransferredBalancesEffects] Load TransferredBalances Success',
  props<{ transferredBalances: TransferredBalance[] }>()
);

export const loadTransferredBalancesFailure = createAction(
  '[TransferredBalancesEffects] Load TransferredBalances Failure',
  props<{ error: string }>()
);

// Add Data To TransferredBalances
export const addDataToTransferredBalances = createAction(
  '[TransferredBalancesEffects] Add Data To TransferredBalances',
  props<{
    transferredBalances: TransferredBalance[];
    schoolYears: SchoolYear[];
  }>()
);

export const addDataToTransferredBalancesSuccess = createAction(
  '[TransferredBalancesEffects] Add Data To TransferredBalances Success',
  props<{ transferredBalances: TransferredBalance[] }>()
);

export const addDataToTransferredBalancesFailure = createAction(
  '[TransferredBalancesEffects] Add Data To TransferredBalances Failure',
  props<{ error: string }>()
);

// Select TransferredBalance
export const selectTransferredBalance = createAction(
  '[TransferredBalancesListContainer] Select TransferredBalance',
  props<{ transferredBalance: TransferredBalance }>()
);

// Calculate Transferred Balances In Date Range
export const calculateTransferredBalancesInDateRange = createAction(
  '[TransferredBalancesEffects] Calculate Transferred Balances In Date Range',
  props<{
    startDate: Date;
    endDate: Date;
    schoolYears: SchoolYear[];
    transferredBalances: TransferredBalance[];
  }>()
);

export const calculateTransferredBalancesInDateRangeSuccess = createAction(
  '[TransferredBalancesEffects] Calculate Transferred Balances In Date Range Success',
  props<{ transferredBalancesInDateRangeInMinutes: number }>()
);

export const calculateTransferredBalancesInDateRangeFailure = createAction(
  '[TransferredBalancesEffects] Calculate Transferred Balances In Date Range Failure',
  props<{ error: string }>()
);
